// Imports
import React from 'react';

// Components
import { Text } from '@radix-ui/themes';
import Page from './../../elements/Page';
import Section from './../../elements/Section';
import ContactCTA from '../../sections/ContactCTA';

// Trailers component
const Trailers = () => {

	// Return component
	return (

		<Page
			title="Trailers"
			description="Viking Events offers versatile trailer hire for personal projects, moving, or machinery transport."
			pathname="services/trailers"
			heroImage="photos/general/site-1.png"
		>

			<Section>

				<Text
					mt="20px"
				>At Viking Events, we offer a versatile trailer hire service designed for individuals and businesses looking to transport goods efficiently. Whether you need a trailer for a personal project, moving house, or transporting machinery, we have a range of options to suit your needs.</Text>

				<Text
					mt="20px"
				>We provide a variety of trailers for hire, including:</Text>

				<ul>
					<li>22ft 3-Axle Flatbed</li>
					<li>14ft 2-Axle Flatbed</li>
				</ul>

				<Text
					mt="20px"
				>For those who need to transport items but prefer not to drive a trailer themselves, our delivery service is the perfect solution. Our professional team will handle the driving for you, ensuring your items are transported safely and efficiently. Key features of our delivery service include:</Text>

				<ul>
					<li>Flexible Use: Our trailers can be hired for various purposes, from moving house to transporting goods for events or machinery.</li>
					<li>Stress-Free Transportation: You can focus on your tasks while we take care of the logistics, delivering your items directly to your desired location.</li>
					<li>Reliable Service: Our team is committed to timely deliveries, ensuring that your items arrive when and where you need them.</li>
				</ul>

				<Text
					mt="20px"
				>Choosing Viking Events means opting for quality and reliability. Our trailers are meticulously maintained and designed to accommodate a wide range of transport needs. Our knowledgeable team is dedicated to helping you select the right trailer for your specific requirements, ensuring that you have the best solution for your transport challenges. We prioritize safety in all our operations, so you can trust that your items will be transported securely and efficiently. With us, you’re not just hiring a trailer; you’re gaining a partner committed to making your transportation experience as smooth as possible.</Text>

				<Text
					mt="20px"
				>At Viking Events, we’re here to simplify your transportation needs with our trailer hire and delivery services. Whether for personal use or business purposes, we invite you to contact us today to learn more about how we can assist you!</Text>

			</Section>

			<ContactCTA />

		</Page>

    )

};

// Export Component
export default Trailers;
