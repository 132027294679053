// Imports
import React from 'react';
import PropTypes from 'prop-types';
import { TextArea } from '@radix-ui/themes'

// TextInput component
const TextInput = React.forwardRef((props, ref) => {

	// Return component
	return (

        <TextArea 
            placeholder={props.placeholder}
            ref={ref}
            mt="10px"
            mb="20px"
            ml="1px"
            fontSize="14px"
            disabled={props.disabled}
            color="orange"
        >
        </TextArea>

    )

});

// Props
TextInput.propTypes = {
    placeholder: PropTypes.string.isRequired,
    disabled: PropTypes.bool
};

// Export Component
export default TextInput;
