// Imports
import React, { useRef } from 'react';

// Hooks
import { useIsMobile } from '../../../hooks/useIsMobile';

// Components
import { Flex, Text, Heading } from '@radix-ui/themes';
import Page from '../../elements/Page';
import Section from '../../elements/Section';
import TextInput from '../../inputs/TextInput';
import InputHeading from '../../inputs/InputHeading';
import TextAreaInput from '../../inputs/TextAreaInput';
import PrimaryButton from '../../buttons/PrimaryButton';

// Utils
import spinner from '@vikingevents/viking-fe-util-spinner';
import notification from '@vikingevents/viking-fe-util-notification';
import api from '@vikingevents/viking-fe-util-api';
import validate from '@vikingevents/viking-fe-util-validate';
import defaults from '../../../utils/defaults';

// Contact component
const Contact = () => {

	// Is Mobile
	const isMobile = useIsMobile();

	// Refs
	const nameRef = useRef();
	const emailRef = useRef();
	const messageRef = useRef();

	// Submit button clicked
	const submitButtonClicked = async () => {

		// Get values
		let name = nameRef.current.value;
		let email = emailRef.current.value;
		let message = messageRef.current.value;

		// Validate
		if (!validate.string(name)) {
			notification.error("Please enter your name.");
			return;
		}
		if (!validate.email(email)) {
			notification.error("Please enter a valid email address.");
			return;
		}
		if (!validate.string(message)) {
			notification.error("Please enter a message.");
			return;
		}

		// Show spinner
		spinner.show();

		api.send({
			method: "POST",
			cacheKey: defaults.consts.CACHE_KEY,
			sessionKey: defaults.consts.SESSION_KEY,
			api: "viking_main",
			endpoint: "/contact/submit",
			requiresAuth: false,
			data: {
				name: name,
				email: email,
				message: message
			}
		}).then((response) => {

			nameRef.current.value = "";
			emailRef.current.value = "";
			messageRef.current.value = "";
			
			notification.success("Thank you getting in touch! We will get back to you as soon as possible.");

			spinner.hide();

		}).catch((error) => {

			spinner.hide();

		});

	}

	// Return component
	return (

		<Page
			title="Contact"
			description="Whether you are looking for work, someone to help make your event happen or just need to pick our brains, the Viking team are always on hand to help."
			pathname="contact"
			heroImage="photos/general/show-1.png"
		>

			<Section>

				<Text>Whether you're seeking employment opportunities, need assistance to bring your event vision to life, or simply want to chat with our knowledgeable team, the Viking Events team is always here to help!</Text>

				<Heading
					className="font-family-black font-size-22"
					mt="40px"
				>How Can We Help?</Heading>

				<Flex
					width={isMobile ? "100%" : "70%"}
					direction="column"
					mt="20px"
				>

					<InputHeading>What should we call you?</InputHeading>

					<TextInput
						type="text"
						placeholder="What should we call you?"
						ref={nameRef}
					/>

					<InputHeading>Where can we reach you?</InputHeading>

					<TextInput
						type="email"
						placeholder="What email can we reach you at?"
						ref={emailRef}
					/>

					<InputHeading>How can we help?</InputHeading>

					<TextAreaInput
						placeholder="How can we help?"
						ref={messageRef}
					/>

					<PrimaryButton
						onClick={submitButtonClicked}
					>SUBMIT
					</PrimaryButton>

				</Flex>

			</Section>

			<Section
				isPrimary
			>

				<Flex
					width="100%"
					minHeight="10px"
					overflowY="auto"
					overflowX="hidden"
					justify="center"
					align="center"
					direction="column"
					mt="20px"
				>

					<Heading
						className="font-family-black font-size-28 colour-white"
						mb="20px"
					>Our Details</Heading>

					<Text
						className="colour-white"
					>
						<a 
							href="mailto:info@vikingeventsgroup.com"
							className="colour-white-no-hover"
						>info@vikingeventsgroup.com</a>
					</Text>

					<Text
						className="colour-white"
						mt="10px"
					>
						<a 
							href="tel:02084322265"
							className="colour-white-no-hover"
						>020 8432 2265</a>
					</Text>

				</Flex>

			</Section>

		</Page>

    )

};

// Export Component
export default Contact;