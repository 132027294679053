// Imports
import React from 'react';

// Components
import { Text } from '@radix-ui/themes';
import Page from './../../elements/Page';
import Section from './../../elements/Section';
import ContactCTA from '../../sections/ContactCTA';

// Crew component
const Crew = () => {

	// Return component
	return (

		<Page
			title="Crew"
			description="Festival site crew members are essential to the success of any live event."
			pathname="services/crew"
			heroImage="photos/general/team-3.png"
		>

			<Section>

				<Text
					mt="20px"
				>At Viking Events, our festival site crew members are the backbone of every live event, playing a crucial role in ensuring its success. These dedicated individuals are responsible for a variety of tasks, including:</Text>

				<ul>
					<li>Setting up and dismantling all types of fencing and barrier.</li>
					<li>Loading and unloading equipment.</li>
					<li>Installing scaffolding.</li>
					<li>Ensuring the site is operational and safe for all attendees</li>
				</ul>

				<Text
					mt="20px"
				>In addition to their technical skills, our crew members excel in customer service, often being the first point of contact for guests and performers. They are knowledgeable about the festival layout, schedule, and amenities, ensuring a positive experience for everyone involved.</Text>

				<Text
					mt="20px"
				>Working as a festival crew member demands physical stamina, flexibility, and the ability to thrive under pressure. Our team is prepared to work long hours in any weather, adapting to changing schedules and last-minute requests with ease.</Text>

				<Text
					mt="20px"
				>At Viking Events, we take pride in training our crew from the ground up. We uphold high standards and expect all our staff to adhere to our specific ways of working. Our crew is always the first to arrive on-site and the last to leave, ensuring that every detail is attended to. Additionally, all crew chiefs and managers have completed their ACT Counter Terror Training courses, prioritizing safety and preparedness.</Text>

				<Text
					mt="20px"
				>Ultimately, our festival site crew members are essential to creating unforgettable experiences for both attendees and performers. Their hard work and dedication ensure that every festival runs smoothly and safely, leaving a lasting impression on all involved.</Text>

			</Section>

			<ContactCTA />

		</Page>

    )

};

// Export Component
export default Crew;
