// Imports
import React from 'react';
import { useNavigate } from 'react-router-dom';

// Hooks
import { useIsMobile } from '../../../hooks/useIsMobile';

// Components
import { Flex, Text, Heading, Button } from '@radix-ui/themes';
import DarkButton from '../../buttons/DarkButton';

// ContactCTA component
const ContactCTA = () => {

    // Hooks
    const navigate = useNavigate();
    const isMobile = useIsMobile();

    // Start now button clicked
    const startNowButtonClicked = () => {
        
        navigate("/contact");

    }

	// Return component
	return (

        <Flex
            width="100%"
            minHeight="400px"
            overflowY="auto"
            overflowX="hidden"
            className="background-primary"
            justify="center"
            align="center"
            direction="column"
            pl={isMobile ? "20px" : "0px"}
            pr={isMobile ? "20px" : "0px"}
        >

            <Heading
                className={isMobile ? "font-family-black font-size-38 colour-white text-align-center font-line-height-40" : "font-family-black font-size-38 colour-white"}
            >Make Your Event A Reality</Heading>

            <Text
                className={isMobile ? "colour-white font-size-18 font-weight-medium text-align-center" : "colour-white font-size-18 font-weight-medium"}
                mt="40px"
                mb="40px"
            >Get in touch today and see how our modern and out-of-the-box thinking can help turn your dreams into a reality.</Text>

            <DarkButton
                onClick={startNowButtonClicked}
                isLarge
            >Start Now</DarkButton>

        </Flex>

    )

};

// Export Component
export default ContactCTA;
