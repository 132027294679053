// Imports
import React, { useEffect, useState } from 'react';
import { ImageGallery } from "react-image-grid-gallery";

// Components
import Page from './../../elements/Page';
import Section from './../../elements/Section';

// Utils
import assets from '@vikingevents/viking-fe-util-assets';
import api from '@vikingevents/viking-fe-util-api';
import spinner from '@vikingevents/viking-fe-util-spinner';
import defaults from '../../../utils/defaults';

// Gallery component
const Gallery = () => {

    // State
    const [images, setImages] = useState([{
        alt: "",
        caption: "",
        src: ""
    }]);

    // Get images
    useEffect(() => {

        const getImages = async () => {

            spinner.show();

            await api.send({
                method: "GET",
                cacheKey: defaults.consts.CACHE_KEY,
                sessionKey: defaults.consts.SESSION_KEY,
                cacheableEndpoints: defaults.consts.CACHEABLE_ENDPOINTS,
                api: "viking_main",
                endpoint: "/gallery",
                requiresAuth: false
            }).then((response) => {

                let updatedImages = [];
                response.data.gallery.forEach((image) => {
                    updatedImages.push({
                        src: `https://viking-assets-public.s3.amazonaws.com/${image.file}`,    
                        alt: image.name,
                        caption: image.name
                    });
                })

                setImages(updatedImages);
                spinner.hide();

            }).catch((error) => {
                spinner.hide();
            });
        }
        
        getImages();

    }, []);

	// Return component
	return (

        <Page
            title="Gallery"
            description="Your top trump. The Ace in your hand. We are the people who will be by your side, day or night, to craft your vision into a physical reality."
            pathname="gallery"
            heroImage="photos/general/gallery.png"
        >

            <Section>

                <ImageGallery 
                    imagesInfoArray={images} 
                    columnWidth={230} 
                    gapSize={24} 
                />                

            </Section>

        </Page>

    )

};

// Export Component
export default Gallery;