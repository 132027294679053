// Imports
import React from 'react';
import PropTypes from 'prop-types';

// Components
import { Button } from '@radix-ui/themes';

// DarkButton component
const DarkButton = (props) => {

	// Return component
	return (

		<Button
            loading={props.loading || false}
            onClick={props.onClick}
            className="background-dark-grey-hover"
            size={(props.isLarge) ? "4" : "3"}
        >{props.children}</Button>

    )

};

// Props
DarkButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    isLarge: PropTypes.bool,
}

// Export Component
export default DarkButton;
