// Imports
import React, { useState } from 'react';

// Hooks
import { useIsMobile } from '../../../hooks/useIsMobile';

// Components
import { Text, Heading, Flex } from '@radix-ui/themes';
import Page from './../../elements/Page';
import Section from './../../elements/Section';
import ContactCTA from '../../sections/ContactCTA';
import Image from '../../elements/Image';
import Cover from '../../elements/Cover';
import HireModal from '../../sections/HireModal';

// Utils
import pageScroll from '../../../utils/pageScroll';

// Containers component
const Containers = () => {

	// Is Mobile
	const isMobile = useIsMobile();

	// State
	const [modalIsVisible, setModalIsVisible] = useState(false);
	const [modalContent, setModalContent] = useState(null);

	// Container Items
	const containerItems = [
		{
			title: 'Sleeper Cabins',
			image: "photos/coming-soon-760.png?w=760",
			content: [
				{
					type: "p",
					content: "At Viking Events, we provide comfortable and stylish sleeper cabins designed to meet the needs of event staff and artists. We offer two types of cabins: one that accommodates up to seven people and another that comfortably sleeps two, complete with ensuite bathrooms for added privacy and convenience. Each cabin is fully equipped with modern amenities, including TVs, fridges, wardrobes, and more, ensuring a cozy and welcoming environment that makes resting between shows a pleasure."
				},
				{
					type: "p",
					content: "Our sleeper cabins are thoughtfully decorated to create an inviting atmosphere, making them more than just a place to sleep—they're a retreat where your team can relax and recharge. If additional items are needed, such as mirrors, kettles, or other personal touches, we’re committed to customizing the cabins to meet our clients’ specific requirements. With Viking Events, you can trust that your team will have a comfortable space to rest, allowing them to perform at their best. Contact us today to learn more about our sleeper cabin options!"
				}
			],
			gallery: []
		},
		{
			title: 'Artist Dressing Rooms',
			image: "photos/general/artist-dressing-rooms-760.png?w=760",
			content: [
				{
					type: "p",
					content: "At Viking Events, our Artist Dressing Rooms are designed to be a massive time saver for event organizers and artists alike. Ready to go as soon as they’re dropped into position, our dressing rooms eliminate the hassle of setting up tents or empty cabins. There's no need to move furniture in and out or spend time decorating—our cabins come fully equipped and thoughtfully arranged, allowing artists to focus on what matters most: their performance."
				},
				{
					type: "p",
					content: "Crafted to the highest quality, our Artist Dressing Rooms provide a welcoming and comfortable environment where artists and their friends can relax, get changed, shower, and unwind after their sets. We understand that every artist has unique requirements, which is why we take pride in customizing each dressing room to reflect their personal style and needs. This attention to detail ensures that every artist feels truly at home while on site. With Viking Events, you can rest assured that your performers will have a dedicated space that enhances their experience and contributes to a successful event. Contact us today to learn more about our Artist Dressing Rooms!"
				}
			],
			gallery: [
				"photos/artist-dressing-rooms/1.png",
				"photos/artist-dressing-rooms/2.png",
				"photos/artist-dressing-rooms/3.png",
				"photos/artist-dressing-rooms/4.png",
				"photos/artist-dressing-rooms/5.png",
				"photos/artist-dressing-rooms/6.png",
				"photos/artist-dressing-rooms/7.png",
				"photos/artist-dressing-rooms/8.png",
				"photos/artist-dressing-rooms/9.png",
				"photos/artist-dressing-rooms/10.png",
				"photos/artist-dressing-rooms/11.png",
				"photos/artist-dressing-rooms/12.png",
				"photos/artist-dressing-rooms/13.png",
				"photos/artist-dressing-rooms/14.png",
			]
		},
		{
			title: "Site Offices",
			image: "photos/coming-soon-760.png?w=760",
			content: [
				{
					type: "p",
					content: "At Viking Events, we understand the importance of having a functional and efficient workspace on-site, which is why we offer versatile Site Offices tailored to your needs. You can choose from empty offices equipped with the essentials—like power and lighting—or opt for fully kitted-out spaces that enable your team to hit the ground running without wasting any time on setup. Our goal is to create an environment that fosters productivity and collaboration, right where you need it."
				},
				{
					type: "p",
					content: "Like all of our services, our Site Offices are completely customizable to meet your specific requirements. Whether you need larger TVs on the walls, a kitchenette for meal prep, or dedicated office Wi-Fi for seamless connectivity, we have you covered. We work closely with our clients to ensure every detail is addressed, delivering a site office that not only meets but exceeds expectations. Contact us today to discuss how we can create the perfect workspace for your event!"
				}
			],
			gallery: []
		}
	]

	// Open modal
	const openModal = (item) => {
		setModalContent(item);
		setModalIsVisible(true);
		pageScroll.stop();
	}

	// Close modal 
	const closeModal = () => {
		setModalIsVisible(false);
		setModalContent(null);
		pageScroll.start();
	}

	// Return component
	return (

		<Page
			title="Containers"
			description="Viking Events offers storage containers, sleeper cabins, artist dressing rooms, and site offices—tailored solutions to ensure your event runs smoothly."
			pathname="containers"
			heroImage="photos/general/artist-dressing-rooms.png"
		>

			<Section>

				<Text
					mt="20px"
				>At Viking Events, we offer a comprehensive range of container services tailored to meet the diverse needs of our clients. Whether you require standard storage containers or specialized units such as sleeper cabins, artist dressing rooms, or site offices, we have the perfect solution to ensure your event runs smoothly. Our container hire options provide flexibility and practicality for any setup, ensuring your equipment and personnel are well accommodated.</Text>

				<Text
					mt="20px"
				>In addition to our hire services, we also specialize in custom container builds, allowing you to create unique spaces that align with your event’s vision. From purpose-built bars and immersive rooms to containers designed to showcase your event’s branding, our team can bring your ideas to life. For those looking to invest, we offer container purchases, working closely with select suppliers to secure the best prices for our clients. Finally, we provide off-site container storage, offering a safe and secure yard for items that are used year after year, ensuring your materials are protected between shows. Contact us today to discover how our container services can enhance your event!</Text>

			</Section>

			<Section noPaddingTop>

				<Heading>What We Stock</Heading>

				<Flex
					width="100%"
					minHeight="100px"
					overflowX="hidden"
					overflowY="auto"
					wrap="wrap"
				>

					{containerItems.map((item, index) => (

						<Flex
							width={isMobile ? "100%" : "380px"}
							height={isMobile ? "100%" : "380px"}
							ml={isMobile ? "0px" : (index % 2) ? '40px' : '0'}
							mt="40px"
							position="relative"
							key={index}
							className="cursor-pointer"
							onClick={() => { openModal(item) }}
						>

							<Image
								src={item.image}
								alt={item.title}
								isSquare
							/>

							<Cover canHover>

								<Heading
									className={isMobile ? "colour-white font-family-black text-align-center" : "colour-white font-family-black"}
								>{item.title}</Heading>

							</Cover>

						</Flex>

					))}

				</Flex>

			</Section>

			<ContactCTA />

			{(modalIsVisible && modalContent) && 
				<HireModal
				content={modalContent}
				onClose={closeModal}
				/>
			}

		</Page>

    )

};

// Export Component
export default Containers;
