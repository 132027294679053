// Imports
import React from 'react';

// Components
import { Flex, Text, Heading } from '@radix-ui/themes';
import Image from '../../elements/Image';
import Cover from '../../elements/Cover';

// PageNotFound component
const PageNotFound = () => {

	// Return component
	return (

		<Flex
			width="100%"
			height="100vh"
			overflow="hidden"
			justify="center"
			align="center"
			position="relative"
		>

				<Image 
					src="photos/general/pnf.png"
					alt="Audley End Festival 2022"
					isTall
				/>

				<Cover>

					<Heading
						className="font-family-black colour-white font-size-110"
					>Oops...</Heading>

					<Heading
						className="font-weight-500 colour-white font-size-34"
						mt="100px"
						mb="60px"
					>It looks like we can't find the page you are looking for.</Heading>

					<Text
						className="colour-white"
					>Maybe the link has changed or the page has been removed.</Text>

				</Cover>

		</Flex>

    )

};

// Export Component
export default PageNotFound;
