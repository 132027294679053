// Imports
import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

// Views
import PageNotFound from '../views/PageNotFound';
import Home from '../views/Home';
import Services from '../views/Services';
import About from '../views/About';
import Team from '../views/Team';
import News from '../views/News';
import Contact from '../views/Contact';
import Crew from '../views/Crew';
import EventDesign from '../views/EventDesign';
import Hire from '../views/Hire';
import Safety from '../views/Safety';
import EMPSag from '../views/EMPSag';
import Consulting from '../views/Consulting';
import Careers from '../views/Careers';
import Privacy from '../views/Privacy';
import Terms from '../views/Terms';
import RecentProject from '../views/RecentProject';
import NewsArticle from '../views/NewsArticle';
import Gallery from '../views/Gallery';
import Containers from '../views/Containers';
import Trailers from '../views/Trailers';
import Structures from '../views/Structures';

// Components
import 'react-toastify/dist/ReactToastify.css';
import { Theme } from '@radix-ui/themes';
import Header from '../navigation/Header';
import Spinner from '../feedback/Spinner';
import Footer from '../navigation/Footer';

// Container component
const Container = () => {

    return (

        <Theme>

            <Header />

            <Routes>

            <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/team" element={<Team />} />
                <Route path="/news" element={<News />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/recent-project/:id" element={<RecentProject />} />
                <Route path="/news/:id" element={<NewsArticle />} />
                <Route path="/gallery" element={<Gallery />} />
                <Route path="/hire" element={<Hire />} />
                <Route path="/containers" element={<Containers />} />
                <Route path="/structures" element={<Structures />} />

                <Route path="/careers" element={<Careers />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/terms" element={<Terms />} />

                <Route path="/services" element={<Services />} />
                <Route path="/services/crew" element={<Crew />} />
                <Route path="/services/event-design" element={<EventDesign />} />
                <Route path="/services/safety" element={<Safety />} />
                <Route path="/services/emp-sag" element={<EMPSag />} />
                <Route path="/services/consulting" element={<Consulting />} />
                <Route path="/services/trailers" element={<Trailers />} />

                <Route path="/404" element={<PageNotFound />} />

                <Route path="*" element={<Navigate to="/404" />} replace />

            </Routes>

            <Footer />

            <ToastContainer />

            <Spinner />

        </Theme>

    );

};

export default Container;
