// Imports
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ImageGallery } from "react-image-grid-gallery";

// Hooks
import { useIsMobile } from '../../../hooks/useIsMobile';

// Components
import { Flex, Heading, Text } from '@radix-ui/themes';

// Utils
import assets from '@vikingevents/viking-fe-util-assets';

// HireModal component
const HireModal = (props) => {

    // Is Mobile
    const isMobile = useIsMobile();

    // State
    const [images, setImages] = useState([{
        alt: "",
        caption: "",
        src: ""
    }]);

    // Get images
    useEffect(() => {

        let updatedImages = [];
        props.content.gallery.forEach((image, index) => {
            updatedImages.push({
                src: assets.getURL('viking', image),    
                alt: `${props.content.title} ${index + 1}`,
                caption: ''
            });
        })

        setImages(updatedImages);

    }, [props.content]);

	// Return component
	return (

		<Flex
			width={isMobile ? "90%" : "700px"}
            height="800px"
            position="fixed"
            overflow="scroll"
            m="auto"
            top="0"
            left="0"
            bottom="0"
            right="0"
            className="z-index-1000 background-very-light-grey shadow-5"
            direction="column"
		>
			
            <Flex
                width="100%"
                height="80px"
                className="background-primary"
                justify="between"
                align="center"
                pl="40px"
                pr="40px"
                flexShrink="0"
            >

                <Heading
                    className="font-family-black colour-white"
                >{props.content.title}</Heading>

                <Text
                    className="font-size-32 colour-white cursor-pointer"
                    onClick={() => { props.onClose() }}
                >x</Text>

            </Flex>

            <Flex
                width="100%"
                height="auto"
                direction="column"
                pl="40px"
                pr="40px"
                mt="20px"
            >

                {props.content.content.map((content, index) => {

                    if (content.type === 'p') {

                        return (
                            <Text 
                                mt="20px"
                                key={index}
                            >{content.content}</Text>
                        );

                    }

                    if (content.type === 'ul') {

                        return (
                            <ul key={index}>
                                {content.content.map((item, lIndex) => (
                                    <li key={lIndex}>{item}</li>
                                ))}
                            </ul>
                        )

                    }

                })}

            </Flex>

            {images.length > 0 && 

                <Flex
                    width="100%"
                    height="auto"
                    className="background-primary"
                    p="24px"
                    mt="40px"
                >

                    <ImageGallery 
                        imagesInfoArray={images} 
                        columnWidth={230} 
                        gapSize={24} 
                    />  

                </Flex>

            }

		</Flex>

    )

};

HireModal.propTypes = {
    content: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired
}

// Export Component
export default HireModal;
