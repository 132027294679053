// Imports
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

// Components
import Page from './../../elements/Page';
import Section from './../../elements/Section';
import ContentViewer from './../../elements/ContentViewer';

// Utils
import spinner from '@vikingevents/viking-fe-util-spinner';
import api from '@vikingevents/viking-fe-util-api';
import defaults from '../../../utils/defaults';

// NewsArticle component
const NewsArticle = () => {

    const { id } = useParams();

	// State
	let [news, setNews] = useState(null);

	// Get news
	useEffect(() => {

		spinner.show();

		api.send({
			method: "GET",
			cacheKey: defaults.consts.CACHE_KEY,
			sessionKey: defaults.consts.SESSION_KEY,
			cacheableEndpoints: defaults.consts.CACHEABLE_ENDPOINTS,
			api: "viking_main",
			endpoint: "/news/" + id,
			requiresAuth: false
		}).then(response => {

			let newsArticle = response.data.news;

			let firstParagraph = "";
			newsArticle.content.forEach((contentItem, index) => {
				if (contentItem.tag === "p" && firstParagraph === "") {
					firstParagraph = contentItem.content[0];
				}
			});

			// Set news
			setNews(newsArticle);

			spinner.hide();

		}).catch(error => {

			spinner.hide();

		});

	}, []);

	// Return component
	if (news === null) {
        return null;
    }

	return (

        <Page
            title=""
            description={news.description || ""}
            pathname={`recent-project/${news._id}?title=${news.title}`}
            heroImage={news.image}
        >

            <Section>

				<ContentViewer 
					content={news.content}
					media={news.media}
				/>

            </Section>

        </Page>

    )

};

// Export Component
export default NewsArticle;
