// Imports
import React, { useState, useEffect, Fragment, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { NavLink } from 'react-router-dom';

// Components
import { Flex, Separator } from '@radix-ui/themes';
import Image from '../../elements/Image';

// Hooks
import { useClientWidth } from '../../../hooks/useClientWidth';

// Assets
import LogoWhite from '../../../assets/logos/logo-white-120.png';
import MenuIcon from '../../../assets/icons/menu-icon.png';

// Header component
const Header = () => {

	// Refs
	const mobileNavRef = useRef(null);

	// Nav links
	const navLinks = [
		{
			title: "Hire",
			link: "/hire"
		},
		{
			title: "Containers",
			link: "/containers"
		},
		{
			title: "Structures",
			link: "/structures"
		},
		{
			title: "Services",
			link: "/services"
		},
		{
			title: "About",
			link: "/about"
		},
		{
			title: "Team",
			link: "/team"
		},
		{
			title: "News",
			link: "/news"
		},
		{
			title: "Gallery",
			link: "/gallery"
		},
		{
			title: "Get In Touch",
			link: "/contact"
		}
	];

	// Location
	const location = useLocation();

	// State
	const [solidHeader, setSolidHeader] = useState(false);
	const [offset, setOffset] = useState(0);
	const [width, setWidth] = useState(0);
	const [mobileNavIsOpen, setMobileNavIsOpen] = useState(false);

	// Handle location changes
	useEffect(() => {

		setMobileNavIsOpen(false);

		if (window.location.pathname !== "/") {
			setSolidHeader(true);
		}

		window.scrollTo(0, 0);

	}, [location]);

	// Handle scroll and header changes
	useEffect(() => {
        const onScroll = () => setOffset(window.pageYOffset);
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });
        return () => window.removeEventListener('scroll', onScroll);
    }, []);

	if (offset > 270 && !solidHeader && window.location.pathname === "/") {
		setSolidHeader(true);
	} else if (offset <= 270 && solidHeader && window.location.pathname === "/") {
		setSolidHeader(false);
	}

	// Observe viewport width
	const clientWidth = useClientWidth();
	useEffect(() => {
		setWidth(clientWidth);
	}, [clientWidth]);

	// Toggle mobile nav
	const toggleMobileNav = () => {

		let newState = !mobileNavIsOpen;

		if (!newState) {
			mobileNavRef.current.classList.add("slide-out-right");
			setTimeout(() => {
				setMobileNavIsOpen(!mobileNavIsOpen);
				mobileNavRef.current.classList.remove("slide-out-right");
			}, 500);
		} else {
			setMobileNavIsOpen(!mobileNavIsOpen);
		}
		
	};

	if (width > 960) {

		// Return component
		return (

			<Flex
				width="100%"
				height="100px"
				justify="between"
				align="center"
				position="fixed"
				top="0px"
				className={(solidHeader) ? "z-index-90 background-dark-grey" : "z-index-90 background-transparent"}
			>

				<NavLink to="/">

					<Flex 
						width="80px"
						height="80px"
						ml="40px"
						justify="center"
						align="center"
						overflow="hidden"
					>

						<Image 
							asset={LogoWhite} 
							alt="Viking Events" 
						/>

					</Flex>

				</NavLink>

				<Flex
					height="40px"
					minWidth="100px"
					overflowX="auto"
					overflowY="hidden"
					mr="40px"
					justify="end"
					align="center"
				>

					{navLinks.map((navLink, index) => (
						
						(navLink.link === '/contact') ?

							<Flex
								height="100%"
								minWidth="10px"
								overflowX="auto"
								overflowY="hidden"
								mr="10px"
								justify="center"
								align="center"
								key={index}
								className={"background-primary-hover radius-10"}
								pl="10px"
								pr="10px"
							>

								<NavLink 
									to={navLink.link}
									className="colour-white-no-hover font-weight-medium"
								>{navLink.title}</NavLink>

							</Flex>

						:

							<Flex
								height="100%"
								minWidth="10px"
								overflowX="auto"
								overflowY="hidden"
								mr="10px"
								justify="center"
								align="center"
								key={index}
								pl="10px"
								pr="10px"
							>

								<NavLink 
									to={navLink.link}
									className={(window.location.pathname === navLink.link) ? "colour-primary font-weight-medium" : "colour-white font-weight-medium"}
								>{navLink.title}</NavLink>

							</Flex>

					))}

				</Flex>

			</Flex>

		);

	} else {

		// Return component
		return (

			<Fragment>

				<Flex
					width="100%"
					height="100px"
					justify="between"
					align="center"
					position="fixed"
					top="0px"
					className={(solidHeader) ? "z-index-90 background-dark-grey" : "z-index-90 background-transparent"}
				>

					<NavLink to="/">

						<Flex 
							width="80px"
							height="80px"
							ml="40px"
							justify="center"
							align="center"
							overflow="hidden"
						>

							<Image 
								asset={LogoWhite} 
								alt="Viking Events" 
							/>

						</Flex>

					</NavLink>

					<Flex
						width="40px"
						height="40px"
						mr="40px"
						justify="center"
						align="center"
						overflow="hidden"
						onClick={() => { toggleMobileNav(); }}
						className="cursor-pointer"
					>

						<Image
							asset={MenuIcon}
							alt="Menu"
						/>

					</Flex>				

				</Flex>

				{mobileNavIsOpen &&

					<Flex
						width="250px"
						minHeight="100px"
						overflowX="hidden"
						overflowY="auto"
						className="background-primary z-index-90 slide-in-right"
						position="fixed"
						top="100px"
						right="0px"
						justify="end"
						align="end"
						direction="column"
						pr="40px"
						pl="40px"
						pt="20px"
						pb="20px"
						ref={mobileNavRef}
					>

						{navLinks.map((navLink, index) => (
							
							<Flex
								key={index}
								width="100%"
								height="40px"
								justify="end"
								align="end"
								direction="column"
								mt={(index === 0) ? "0px" : (index === navLinks.length - 1) ? "40px" : "10px"}
							>

								{index !== navLinks.length - 1 ?
									
									<Fragment>

										<NavLink 
											to={navLink.link}
											className="colour-white-no-hover font-weight-medium"
										>{navLink.title}</NavLink>

										<Separator 
											size="4" 
											mt="10px"
										/>

									</Fragment>

								:

									<Flex
										width="100%"
										height="100%"
										className="background-dark-grey radius-7"
										justify="center"
										align="center"
									>

										<NavLink 
											to={navLink.link}
											className="colour-white-no-hover font-weight-medium"
										>{navLink.title}</NavLink>

									</Flex>
									
								}

							</Flex>

						))}

					</Flex>

				}

			</Fragment>

		);

	}

};

export default Header;
