// Imports
import React from 'react';
import PropTypes from 'prop-types';

// Hooks
import { useIsMobile } from '../../../hooks/useIsMobile';

// Components
import { Flex } from '@radix-ui/themes';

// Section component
const Section = (props) => {

    // Is Mobile
    const isMobile = useIsMobile();

	// Return component
	return (

        <Flex
            width="100%"
            minHeight="100px"
            overflowX="hidden"
            overflowY="auto"
            pt={props.noPaddingTop ? "0px" : "40px"}
            pb="40px"
            justify="center"
            align="center"
            direction="column"
            className={(props.isPrimary) ? "background-primary" : "background-white"}
        >

            <Flex
                width={(isMobile) ? "90%" : "800px"}
                minHeight="100px"
                overflowX="hidden"
                overflowY="auto"
                pt={props.noPaddingTop ? "0px" : "40px"}
                pb="40px"
                direction="column"
                justify="start"
                align={(props.isCentered) ? "center" : "start"}
            >

                {props.children}

            </Flex>	

		</Flex>

    )

};

// Props
Section.propTypes = {
    isPrimary: PropTypes.bool,
    isCentered: PropTypes.bool,
    noPaddingTop: PropTypes.bool,
}

// Export Component
export default Section;
