// Imports
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

// Components
import Page from './../../elements/Page';
import Section from './../../elements/Section';
import ContentViewer from './../../elements/ContentViewer';

// Utils
import spinner from '@vikingevents/viking-fe-util-spinner';
import api from '@vikingevents/viking-fe-util-api';
import defaults from '../../../utils/defaults';

// RecentProject component
const RecentProject = () => {

    const { id } = useParams();
    const navigate = useNavigate();

    // State
    const [project, setProject] = useState(null);

    // Get recent project
    useEffect(() => {

        spinner.show();
        api.send({
            method: "GET",
            cacheKey: defaults.consts.CACHE_KEY,
            sessionKey: defaults.consts.SESSION_KEY,
            cacheableEndpoints: defaults.consts.CACHEABLE_ENDPOINTS,
            api: "viking_main",
            endpoint: "/recent-projects/" + id,
            requiresAuth: false
        }).then(response => {

            let project = response.data.project;

            let firstParagraph = "";
            project.content.forEach((contentItem, index) => {
                if (contentItem.tag === "p" && firstParagraph === "") {
                    firstParagraph = contentItem.content[0];
                }
            });

            // Set news
            setProject(project);

            spinner.hide();

        }).catch(error => {

            spinner.hide();

        });

    }, []);

	// Return component
    if (project === null) {
        return null;
    }

	return (

        <Page
            title=""
            description={project.description || ""}
            pathname={`recent-project/${project._id}?title=${project.title}`}
            heroImage={project.image}
        >

            <Section>

                <ContentViewer 
                    content={project.content}
                    media={project.media}
                />

            </Section>

        </Page>

    )

};

// Export Component
export default RecentProject;