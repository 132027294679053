// Imports
import React from 'react';


// Hooks
import { useIsMobile } from '../../../hooks/useIsMobile';

// Components
import { Flex, Text, Heading } from '@radix-ui/themes';
import Page from './../../elements/Page';
import Section from './../../elements/Section';
import AboutStats from './../../sections/AboutStats';

// About component
const About = () => {

	// Is Mobile
	const isMobile = useIsMobile();

	// Values
	const values = [
		{
			title: "CREATIVITY",
			text: "We understand that people attend events to experience something unique and memorable. That's why we value creativity in every aspect of event planning and management, from initial concept development through to execution. We take pride in our ability to think outside the box and deliver experiences that exceed our client's expectations."
		},
		{
			title: "SAFETY",
			text: "The safety of our clients, staff, and attendees is of utmost importance to us. We adhere to strict health and safety guidelines and work closely with local authorities and safety advisory groups to ensure our events are as safe as possible. We believe that through thorough safety planning we can create more efficient events that deliver an experience like no other."
		},
		{
			title: "COLLABORATION",
			text: "We understand that successful events require effective collaboration between all parties involved. We work closely with our clients and their teams, as well as with vendors, contractors, and local authorities, to ensure that all aspects of the event are carefully planned and executed to the highest standards. We believe that effective collaboration is key to achieving the best possible outcomes for our clients and creating events that people will remember for years to come."
		},
		{
			title: "INSPIRATION",
			text: "At our company, we believe in inspiring others through our events. We strive to create experiences that not only entertain but also leave a lasting impact on attendees. Whether it's through innovative event design, immersive activations, or meaningful programming, we aim to inspire our clients and their audiences. We also prioritise safety and fun, ensuring that our events not only inspire but also provide a safe and enjoyable environment for everyone involved."
		}
	];

	// Return component
	return (

		<Page 
			title="Who Are We?" 
			description="Welcome to Viking Events, a company that understands, cares and works harder than you could ever imagine." pathname="about" 
			heroImage="photos/general/show-5.png"
		>

			<Section>

				<Text
					mt="20px"
				>Welcome to Viking Events, where we understand, care, and work tirelessly to exceed your expectations. Specializing in comprehensive festival services, we cover everything from layout design and stage management to health and safety compliance and risk assessment. Our dedicated team ensures your festival runs smoothly, safely, and successfully—from the moment your attendees purchase a ticket to their safe journey home.</Text>

				<Heading
					mt="30px"
					className="font-family-black font-size-22"
				>Our Mission</Heading>

				<Text
					mt="20px"
				>At Viking Events, we pride ourselves on our meticulous attention to detail and our unwavering commitment to UK health and safety regulations. Providing a safe environment for your festival-goers is our top priority, and we consistently place the safety of your audience, performers, and staff first.</Text>

				<Heading
					mt="30px"
					className="font-family-black font-size-22"
				>Our Expertise</Heading>

				<Text
					mt="20px"
				>With extensive knowledge of UK health and safety regulations and the guidance provided by the Purple Guide, our team is equipped to navigate the complexities of festival management. We understand that the success of a festival is not solely about the music but also about the overall audience experience. Our experts design layouts that maximize space and ensure smooth traffic flow throughout the festival grounds, considering everything from entry and exit points to crowd management and the placement of essential facilities.</Text>

				<Heading
					mt="30px"
					className="font-family-black font-size-22"
				>Our Experience</Heading>

				<Text
					mt="20px"
				>Over the years, we have successfully managed a diverse range of festivals, from intimate gatherings to large-scale productions with over 25,000 attendees. Our experience includes some of the UK's most renowned festivals, such as Heritage Live, Garage Nation, 51st State Festival, and Boundary Brighton. We have built a reputation for excellence, earning positive feedback from our clients for our dedication and attention to detail.</Text>

				<Heading
					mt="30px"
					className="font-family-black font-size-22"
				>Our Commitment</Heading>

				<Text
					mt="20px"
				>Every festival is unique, and we are committed to collaborating closely with you to bring your vision to life. We work tirelessly to ensure your festival is a resounding success, and we look forward to the opportunity to partner with you.</Text>

				<Text
					mt="20px"
				>Contact us today to learn more about our services and discover how we can help you create a festival that exceeds your expectations!</Text>

			</Section>

			<Section
				isPrimary
			>

				<Flex
					width="100%"
					justify="center"
					align="center"
				>

					<Heading
						className="font-family-black font-size-38 colour-white"
						mt="40px"
						mb="80px"
					>Our Values</Heading>

				</Flex>

				<Flex
					width="100%"
					minHeight="100px"
					overflowX="hidden"
					overflowY="auto"
					justify={isMobile ? "center" : "between"}
					align="center"
					wrap="wrap"
				>

					{values.map((value, index) => (

						<Flex
							width="380px"
							minHeight="440px"
							overflowY="hidden"
							overflowX="hidden"
							justify="center"
							align="center"
							direction="column"
							className="background-dark-grey"
							key={index}
							mb="40px"
						>

							<Heading
								className="font-family-black font-size-16 colour-white font-weight-400"
							>{value.title}</Heading>

							<Flex
								width="80%"
								mt="20px"
							>

								<Text
									className="colour-white text-align-center"
								>{value.text}</Text>

							</Flex>

						</Flex>

					))}

				</Flex>

			</Section>

			<AboutStats />

		</Page>

    )

};

// Export Component
export default About;

