// Imports
import React, { useState } from 'react';

// Hooks
import { useIsMobile } from '../../../hooks/useIsMobile';

// Components
import { Flex, Text } from '@radix-ui/themes';

// Testimonials component
const Testimonials = (props) => {

    // Is Mobile
    const isMobile = useIsMobile();

    // State
    const [activeIndex, setActiveIndex] = useState(0);

    // Testimonials
	const testimonials = [
		{
			text: "Viking Events provided a Festival build service that exceeded expectations, from good communication, to paperwork being on time and most importantly the overall execution of the event itself. As well as benefiting from their wealth of experience they are fun to work with and treat your event like it's their own",
			client: "Nick Beverage - Boundary Brighton"
		},
		{
			text: "The team from Viking have worked with us on a number of projects and events, we can not fault them. The delivery is always on time, within budget and done with a spring in their step. The equipment hired is always like it is fresh out of the factory and their management team handle every single bit of stress with a spring in their step. We wish all our contractors operated with this level of professionalism.",
			client: "James - Garage Nation Festival"
		},
		{
			text: "I had the pleasure of working with Harry and the team for a recent event. Their support was great and their performance and delivery were top-notch. I highly recommend Viking Events for any event production needs.",
			client: "Damien Fell - Multiple Shows/Venues"
		}
	]

	// Return component
	return (

        <Flex
            width="100%"
            minHeight="400px"
            overflowY="auto"
            overflowX="hidden"
            className="background-primary"
            justify="center"
            align="center"
            direction="column"
            position="relative"
        >

			{testimonials.map((testimonial, index) => (

                <Flex
                    width={isMobile ? "90%" : "800px"}
                    minHeight="10px"
                    overflowY="auto"
                    overflowX="hidden"
                    key={index}
                    justify="center"
                    align="center"
                    direction="column"
                    display={(index === activeIndex) ? "flex" : "none"}
                >

                    <Text
                        className="text-align-center colour-white"
                        mb="30px"
                    >"{testimonial.text}"</Text>

                    <Text
                        className="text-align-center colour-white font-weight-700 font-size-18"
                    >{testimonial.client}</Text>

                </Flex>

            ))}

            <Flex
                minWidth="10px"
                height="52px"
                overflowX="auto"
                overflowY="hidden"
                position="absolute"
                margin="auto"
                bottom="0px"
                pb="20px"
                justify="center"
                align="end"
            >

                {testimonials.map((testimonial, index) => (

                    <Flex
                        width="14px"
                        height="14px"
                        className={(index === activeIndex) ? "background-dark-grey radius-half cursor-pointer" : "background-white radius-half cursor-pointer" }
                        cursor="pointer"
                        mr="10px"
                        key={index}
                        onClick={() => { setActiveIndex(index); }}
                    >
                    </Flex>

                ))}

            </Flex>

        </Flex>

    )

};

// Export Component
export default Testimonials;
