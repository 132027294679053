// Imports
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// Hooks
import { useIsMobile } from './../../../hooks/useIsMobile';

// Components
import { Flex, Heading, Text } from '@radix-ui/themes';
import Page from './../../elements/Page';
import Section from './../../elements/Section';
import Image from './../../elements/Image';
import PrimaryButton from './../../buttons/PrimaryButton';

// Utils
import api from '@vikingevents/viking-fe-util-api';
import spinner from '@vikingevents/viking-fe-util-spinner';
import defaults from '../../../utils/defaults';

// News component
const News = () => {

	// Is mobile
	const isMobile = useIsMobile();

	// Navigate
	const navigate = useNavigate();

	// State
	const [news, setNews] = useState([]);

	// Get news
	useEffect(() => {

		spinner.show();

		api.send({
			method: "GET",
			cacheKey: defaults.consts.CACHE_KEY,
			sessionKey: defaults.consts.SESSION_KEY,
			cacheableEndpoints: defaults.consts.CACHEABLE_ENDPOINTS,
			api: "viking_main",
			endpoint: "/news",
			requiresAuth: false
		}).then(response => {

			// Set news
			setNews(response.data.news);

			spinner.hide();

		}).catch(error => {
			spinner.hide();

		});

	}, []);

	// News clicked
	const newsClicked = (news) => { 
		
		navigate(`/news/${news._id}?title=${news.title}`);

	}

	// Return component
	return (

		<Page
			title="News"
			description="Catch up on all the latest Viking Events news."
			pathname="news"
			heroImage="photos/general/general-1.png"
		>

			<Section>

				<Flex
					wrap="wrap"
					width="100%"
					justify={isMobile ? "center" : "start"}
				>

					{news.map((item, index) => {
						
						return (
							<Flex
								key={index}
								width="380px"
								minHeight="200px"
								overflowX="hidden"
								overflowY="auto"
								className="background-very-light-grey"
								ml={isMobile ? "0px" : (index % 2 === 0) ? '0' : '40px'}
								mb="40px"
								direction="column"
								pb="40px"
							>

								<Flex
									align="center"
									justify="center"
									overflow="hidden"
									width="100%"
									height="200px"
								>

									<Image 
										src={`${item.image}?w=760`}
										alt={item.title}
										isMainBucket
									/>

								</Flex>

								<Heading
									className="font-family-black font-size-18 colour-dark-grey"
									ml="20px"
									mr="20px"
									mt="20px"
								>{item.title}</Heading>

								<Text
									ml="20px"
									mr="20px"
									mt="10px"
								>{item.preview}</Text>

								<Text
									className="colour-dark-grey font-size-14"
									ml="20px"
									mr="20px"
									mt="10px"
								>{item.createdAt.display}</Text>

								<Flex
									mt="20px"
									ml="20px"
								>

									<PrimaryButton
										onClick={() => { newsClicked(item) }}
									>Read More</PrimaryButton>

								</Flex>

							</Flex>
						)

					})}

				</Flex>

			</Section>

		</Page>

    )

};

// Export Component
export default News;
