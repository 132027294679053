// Imports
import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

// Hooks
import { useIsMobile } from '../../../hooks/useIsMobile';

// Components
import { Flex, Text, Heading } from '@radix-ui/themes';
import Image from '../../elements/Image';
import Cover from '../../elements/Cover';
import PrimaryButton from '../../buttons/PrimaryButton';

// LearnMore component
const LearnMore = (props) => {

    // Hooks
    const navigate = useNavigate();
    const isMobile = useIsMobile();

    // Create class name
    let className = "learn-more";
    if (props.isDark) {
        className += " isDark";
    }
    if (props.isFlipped) {
        className += " isFlipped";
    }

    // Button clicked
    const buttonClicked = () => {
        navigate(props.content.link);
    }

	// Return component
	return (

        
        <Flex
            width="100%"
            minHeight="558px"
            overflowY="auto"
            overflowX="hidden"
            className={(props.isDark) ? "background-dark-grey" : "background-white"}
            justify="center"
            align="center"
            pt="80px"
            pb="80px"
            direction={isMobile ? "column" : (props.isFlipped) ? "row-reverse" : "row"}
        >

            <Flex
                width={isMobile ? "90%" : "560px"}
                height="360px"
                overflow="hidden"
                justify="center"
                align="center"
                position="relative"
            >

                <Image
                    src={`${props.content.image}?w=900`} 
                    alt={props.content.title} 
                    isWide 
                />

                <Cover></Cover>

            </Flex>

            <Flex
                width={isMobile ? "90%" : "600px"}
                minHeight="10px"
                overflowY="auto"
                overflowX="hidden"
                ml={isMobile ? "0px" : (props.isFlipped) ? "0px" : "80px"}
                mr={isMobile ? "0px" : (props.isFlipped) ? "80px" : "0px"}
                justify="start"
                align="start"
                direction="column"
            >

                <Heading
                    className={(props.isFlipped) ? "colour-white font-family-black font-size-28": "colour-dark-grey font-family-black font-size-28"}
                    mt={isMobile ? "20px" : "0px"}
                >{props.content.title}</Heading>

                <Text
                    mb="30px"
                    mt="20px"
                    className={(props.isFlipped) ? "colour-white": "colour-dark-grey"}
                >{props.content.text}</Text>

                <PrimaryButton
                    onClick={buttonClicked}
                >Learn More</PrimaryButton>

            </Flex>

		</Flex>

    )

};

LearnMore.propTypes = {
    isDark: PropTypes.bool,
    isFlipped: PropTypes.bool,
    content: PropTypes.object.isRequired
}

// Export Component
export default LearnMore;
