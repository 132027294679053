// Imports
import React from 'react';
import PropTypes from 'prop-types';

// Hooks
import { useIsMobile } from '../../../hooks/useIsMobile';

// Components
import { Flex, Heading } from '@radix-ui/themes';
import Image from '../../elements/Image';
import Cover from '../../elements/Cover';

// PageHero component
const PageHero = (props) => {

	// Is Mobile
	const isMobile = useIsMobile();

	// Return component
	return (

		<Flex
			width="100%"
			height="300px"
			className="background-primary"
			justify="center"
			align="center"
			overflow="hidden"
			position="relative"
		>
			
			<Image 
				src={`${props.image}?w=3600`}
				alt={props.title} 
				isWide={isMobile ? false : true}
				isSquare={isMobile ? true : false}
			/>

			<Cover>

				<Heading
					size="8"
					className={isMobile ? "font-family-black colour-white text-align-center" : "font-family-black colour-white"}
				>{props.title}</Heading>

			</Cover>

		</Flex>

    )

};

PageHero.propTypes = {
    title: PropTypes.string.isRequired,
	image: PropTypes.string.isRequired,
}

// Export Component
export default PageHero;
