// Imports
import React from 'react';
import { NavLink } from 'react-router-dom';

// Hooks
import { useIsMobile } from '../../../hooks/useIsMobile';

// Components
import { Flex, Text } from '@radix-ui/themes';
import Image from '../../elements/Image';

// Assets
import LogoWhite from '../../../assets/logos/logo-white-120.png';
import FacebookIcon from '../../../assets/icons/facebook-icon.png';
import InstagramIcon from '../../../assets/icons/instagram-icon.png';
import TikTokIcon from '../../../assets/icons/tiktok-icon.png';

// Footer component
const Footer = () => {

	// Is Mobile
	const isMobile = useIsMobile(970);

	// Socials
	const socials = [
		{
			name: "Instagram",
			link: "https://instagram.com/vikingeventsgroup",
			icon: InstagramIcon
		},
		{
			name: "TikTok",
			link: "https://tiktok.com/@vikingeventsgroup",
			icon: TikTokIcon
		},
		{
			name: "Facebook",
			link: "https://www.facebook.com/vikingeventsgroup",
			icon: FacebookIcon
		}
	];

	// Nav
	const nav = [
		{
			name: "Careers",
			link: "/careers"
		},
		{
			name: "Contact",
			link: "/contact"
		},
		{
			name: "Privacy",
			link: "/privacy"
		},
		{
			name: "Terms",
			link: "/terms"
		}
	]

	// Create copyright text
	let copyStart = 2023;
	let copyCurrent = new Date().getFullYear();
	let copyright = `Copyright © ${copyStart} Viking Entertainment Holdings Ltd. All Rights Reserved`;
	if (copyStart !== copyCurrent) {
		copyright = `Copyright © ${copyStart} - ${copyCurrent} Viking Entertainment Holdings Ltd. All Rights Reserved`;
	}

	return (

		<Flex
			width="100%"
			minHeight="60px"
			overflowX="hidden"
			overflowY="auto"
			className="background-dark-grey"
			justify="between"
			align="center"
			pt="20px"
			pb="20px"
			direction={isMobile ? "column" : "row"}
		>

			<Flex
				minWidth="100px"
				height={isMobile ? "auto" : "80px"}
				overflowY="hidden"
				overflowX="auto"
				justify="start"
				align="center"
				ml={isMobile ? "0px" : "40px"}
				direction={isMobile ? "column" : "row"}
			>

				<Flex
					width="80px"
					height="80px"
					overflow="hidden"
					justify="center"
					align="center"
				>

					<Image asset={LogoWhite} alt="Viking Logo" />

				</Flex>

				<Text
					mt={isMobile ? "10px" : "0px"}
					ml={isMobile ? "0px" : "10px"}
					className={isMobile ? "colour-white font-size-12 text-align-center" : "colour-white font-size-12"}
				>{copyright}</Text>

			</Flex>

			<Flex
				minWidth="100px"
				height="80px"
				overflowY="hidden"
				overflowX="auto"
				justify="end"
				align="center"
				mr={isMobile ? "0px" : "40px"}
				direction={isMobile ? "column" : "row"}
				mt={isMobile ? "20px" : "0px"}
			>

				<Flex
					minWidth="10px"
					height="32px"
					overflowX="auto"
					overflowY="hidden"
					justify="end"
					align="center"
					mr={isMobile ? "0px" : "20px"}
				>

					{nav.map((item, index) => (

						<Flex
							key={index}
							ml="10px"
						>

							<NavLink 
								to={item.link} 
								className="colour-white font-size-14"
							>{item.name}</NavLink>

						</Flex>

					))}

				</Flex>

				<Flex
					minWidth="10px"
					height="38px"
					overflowX="auto"
					overflowY="hidden"
					justify="end"
					align="center"
					mt={isMobile ? "10px" : "0px"}
				>

					{socials.map((social, index) => (

						<Flex
							key={index}
							width="38px"
							height="38px"
							overflow="hidden"
							justify="center"
							align="center"
							ml="0px"
						>

							<NavLink to={social.link} target="_blank">

								<Image asset={social.icon} alt={social.name} />

							</NavLink>

						</Flex>

					))}

				</Flex>

			</Flex>

		</Flex>

	);

};

export default Footer;
