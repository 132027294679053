// Imports
import React, { useRef, useEffect, useState } from 'react';

// Hooks
import { useIntersection } from '../../../hooks/useIntersection';
import { useIsMobile } from '../../../hooks/useIsMobile';

// Components
import { Flex, Heading } from '@radix-ui/themes';
import Section from './../../elements/Section';

// AboutStats component
const AboutStats = () => {

	// Is Mobile
	const isMobile = useIsMobile();

	// Consts
	const animationSpeed = 5;

	// Refs
	const stats2021Ref = useRef();
	const stats2022Ref = useRef();
	const stats2023Ref = useRef();
	const stats2024Ref = useRef();

	// Intersections
	const stats2021IsVisible = useIntersection(stats2021Ref, "0px");
	const stats2022IsVisible = useIntersection(stats2022Ref, "0px");
	const stats2023IsVisible = useIntersection(stats2023Ref, "0px");
	const stats2024IsVisible = useIntersection(stats2024Ref, "0px");

	// Observers
	useEffect(() => {
		if (stats2021IsVisible) {
			stats2021Animation();
		}
	}, [stats2021IsVisible]);

	useEffect(() => {
		if (stats2022IsVisible) {
			stats2022Animation();
		}
	}, [stats2022IsVisible]);

	useEffect(() => {
		if (stats2023IsVisible) {
			stats2023Animation();
		}
	}, [stats2023IsVisible]);

	useEffect(() => {
		if (stats2024IsVisible) {
			stats2024Animation();
		}
	}, [stats2024IsVisible]);

	// State
	const [stats2021Values, setStats2021Values] = useState(['0', '0', '0']);
	const [stats2022Values, setStats2022Values] = useState(['0', '0', '0']);
	const [stats2023Values, setStats2023Values] = useState(['0', '0', '0']);
	const [stats2024Values, setStats2024Values] = useState(['0', '0', '0']);

	// Stats
	const stats = [
		{
			year: "2021",
			ref: stats2021Ref,
			stats: [
				{
					title: "Events Managed",
					target: "17",
					value: stats2021Values[0],
				},
				{
					title: "Crew Supplied",
					target: "973",
					value: stats2021Values[1],
				},
				{
					title: "Barrier Built",
					target: "9.7",
					value: stats2021Values[2],
				},
			]
		},
		{
			year: "2022",
			ref: stats2022Ref,
			stats: [
				{
					title: "Events Managed",
					target: "27",
					value: stats2022Values[0],
				},
				{
					title: "Crew Supplied",
					target: "2137",
					value: stats2022Values[1],
				},
				{
					title: "Barrier Built",
					target: "20.1",
					value: stats2022Values[2],
				},
			]
		},
		{
			year: "2023",
			ref: stats2023Ref,
			stats: [
				{
					title: "Events Managed",
					target: "47",
					value: stats2023Values[0],
				},
				{
					title: "Crew Supplied",
					target: "3789",
					value: stats2023Values[1],
				},
				{
					title: "Barrier Built",
					target: "34.7",
					value: stats2023Values[2],
				},
			]
		},
		{
			year: "2024",
			ref: stats2024Ref,
			stats: [
				{
					title: "Events Managed",
					target: "50",
					value: stats2024Values[0],
				},
				{
					title: "Crew Supplied",
					target: "3978",
					value: stats2024Values[1],
				},
				{
					title: "Barrier Built",
					target: "36.4",
					value: stats2024Values[2],
				},
			]
		}
	]

	// Animation
	const stats2021Animation = () => { 
		
		if (stats2021Values[0] === '0' && stats2021Values[1] === '0' && stats2021Values[2] === '0') {
			
			const animation2021Interval = setInterval(() => {

				setStats2021Values((prevValues) => {

				  let value1 = parseInt(prevValues[0]);
				  let value2 = parseInt(prevValues[1]);
				  let value3 = parseFloat(prevValues[2]);
				  let target1 = parseInt(stats[0].stats[0].target);
				  let target2 = parseInt(stats[0].stats[1].target);
				  let target3 = parseFloat(stats[0].stats[2].target);
		  
				  if (value1 === target1 && value2 === target2 && value3 === target3) {

					clearInterval(animation2021Interval);
					return prevValues;

				  } else {

					if (value1 < target1) {
					  value1 += 1;
					}

					if (value2 < target2) {
					  value2 += 1;
					}

					if (value3 < target3) {
					  value3 += 0.1;
					}

					return [value1.toString(), value2.toString(), value3.toFixed(1)];

				  }

				});

			}, animationSpeed);

		}

	}

	const stats2022Animation = () => { 
		
		if (stats2022Values[0] === '0' && stats2022Values[1] === '0' && stats2022Values[2] === '0') {
			
			const animation2022Interval = setInterval(() => {

				setStats2022Values((prevValues) => {

				  let value1 = parseInt(prevValues[0]);
				  let value2 = parseInt(prevValues[1]);
				  let value3 = parseFloat(prevValues[2]);
				  let target1 = parseInt(stats[1].stats[0].target);
				  let target2 = parseInt(stats[1].stats[1].target);
				  let target3 = parseFloat(stats[1].stats[2].target);
		  
				  if (value1 === target1 && value2 === target2 && value3 === target3) {

					clearInterval(animation2022Interval);
					return prevValues;

				  } else {

					if (value1 < target1) {
					  value1 += 1;
					}

					if (value2 < target2) {
					  value2 += 1;
					}

					if (value3 < target3) {
					  value3 += 0.1;
					}

					return [value1.toString(), value2.toString(), value3.toFixed(1)];

				  }

				});
				
			}, animationSpeed);

		}

	}

	const stats2023Animation = () => { 
		
		if (stats2023Values[0] === '0' && stats2023Values[1] === '0' && stats2023Values[2] === '0') {
			
			const animation2023Interval = setInterval(() => {

				setStats2023Values((prevValues) => {

				  let value1 = parseInt(prevValues[0]);
				  let value2 = parseInt(prevValues[1]);
				  let value3 = parseFloat(prevValues[2]);
				  let target1 = parseInt(stats[2].stats[0].target);
				  let target2 = parseInt(stats[2].stats[1].target);
				  let target3 = parseFloat(stats[2].stats[2].target);
		  
				  if (value1 === target1 && value2 === target2 && value3 === target3) {

					clearInterval(animation2023Interval);
					return prevValues;

				  } else {

					if (value1 < target1) {
					  value1 += 1;
					}

					if (value2 < target2) {
					  value2 += 1;
					}

					if (value3 < target3) {
					  value3 += 0.1;
					}

					return [value1.toString(), value2.toString(), value3.toFixed(1)];

				  }

				});
				
			}, animationSpeed);

		}

	}

	const stats2024Animation = () => { 
		
		if (stats2024Values[0] === '0' && stats2024Values[1] === '0' && stats2024Values[2] === '0') {
			
			const animation2024Interval = setInterval(() => {

				setStats2024Values((prevValues) => {

				  let value1 = parseInt(prevValues[0]);
				  let value2 = parseInt(prevValues[1]);
				  let value3 = parseFloat(prevValues[2]);
				  let target1 = parseInt(stats[3].stats[0].target);
				  let target2 = parseInt(stats[3].stats[1].target);
				  let target3 = parseFloat(stats[3].stats[2].target);
		  
				  if (value1 === target1 && value2 === target2 && value3 === target3) {

					clearInterval(animation2024Interval);
					return prevValues;

				  } else {

					if (value1 < target1) {
					  value1 += 1;
					}

					if (value2 < target2) {
					  value2 += 1;
					}

					if (value3 < target3) {
					  value3 += 0.1;
					}

					return [value1.toString(), value2.toString(), value3.toFixed(1)];

				  }

				});
				
			}, animationSpeed);

		}

	}

	// Return component
	return (

		<Section>

            {stats.map((stat, index) => (

                <Flex
                    key={index}
                    width="100%"
                    minHeight="10px"
                    overflowX="hidden"
                    overflowY="auto"
                    justify="center"
                    align="center"
                    direction="column"
                    mb={(index === stats.length - 1) ? "0px" : "100px"}
                >

                    <Heading
                        className="font-family-black font-size-38 colour-dark-grey font-weight-400"
                        mb="40px"
                        mt={(index === 0) ? "40px" : "0px"}
                    >{stat.year}</Heading>

                    <Flex
                        width="100%"
                        minHeight="10px"
                        overflowX="hidden"
                        overflowY="auto"
                        justify="center"
                        align={isMobile ? "start" : "center"}
                        mt="40px"
                        ref={stat.ref}
                    >

                        {stat.stats.map((stat, sIndex) => (

                            <Flex
                                key={sIndex}
                                width="160px"
                                minHeight="100px"
								overflowX="hidden"
								overflowY="auto"
                                ml={isMobile ? (sIndex === 0) ? "0px" : "20px" : (sIndex === 0) ? "0px" : "80px"}
                                justify="center"
                                align="center"
                                direction="column"
                            >

                                <Flex
                                    width={isMobile ? "80px" : "120px"}
                                    height={isMobile ? "80px" : "120px"}
                                    justify="center"
                                    align="center"
                                    className={isMobile ? "border-thick-primary radius-half font-size-16 font-family-black colour-dark-grey" : "border-thick-primary radius-half font-size-22 font-family-black colour-dark-grey"}
                                >
                                    {(stat.title === "Barrier Built") ? `${stat.value}km`: stat.value}
                                </Flex>

                                <Heading
                                    className={isMobile ? "font-size-18 colour-dark-grey font-weight-500 text-align-center" : "font-size-18 colour-dark-grey font-weight-500"}
                                    mt="20px"
                                >{stat.title}</Heading>

                            </Flex>

                        ))}

                    </Flex>

                </Flex>

            ))}

        </Section>

    )

};

// Export Component
export default AboutStats;