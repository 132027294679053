// Packages
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.scss'

// Comonents
import Container from './components/Container';
import ErrorBoundary from './components/ErrorBoundary';
import { ToastContainer } from 'react-toastify';

// Toast
import { injectStyle } from "react-toastify/dist/inject-style";
injectStyle();

// Render
const container = document.getElementById('application');
const root = createRoot(container);
root.render(<BrowserRouter>

	<ErrorBoundary>

		<Container/>

		<ToastContainer
			position="bottom-right"
			autoClose={5000}
			hideProgressBar={false}
			newestOnTop={false}
			closeOnClick
			rtl={false}
			pauseOnFocusLoss
			draggable
			pauseOnHover
			style={{ fontSize: "14px", fontFamily: `'archivo', sans-serif` }}
		/>

	</ErrorBoundary>


</BrowserRouter>);
