// Imports
import React from 'react';

// Components
import { Text } from '@radix-ui/themes';
import Page from './../../elements/Page';
import Section from './../../elements/Section';
import ContactCTA from '../../sections/ContactCTA';

// EventDesign component
const EventDesign = () => {

	// Return component
	return (

		<Page
			title="Event Design"
			description="At Viking Events, we believe that excellent event design is the cornerstone of any successful live event."
			pathname="services/event-design"
			heroImage="photos/general/site-1.png"
		>

			<Section>

				<Text
					mt="20px"
				>At Viking Events, we believe that exceptional event design is the foundation of any successful live experience. Our team uses industry-standard software to create detailed, to-scale site plans that are clear and easy to read, ensuring every aspect of your event is meticulously planned.</Text>

				<Text
					mt="20px"
				>We value collaboration and offer flexibility in our design process. Clients can visit our state-of-the-art design suite—complete with a great coffee machine and refreshments—or we can come to your office to discuss your vision in detail.</Text>

				<Text
					mt="20px"
				>Key aspects of our event design include:</Text>

				<ul>
					<li>Enhanced Customer Experience: Our designs are crafted to boost attendee satisfaction while being mindful of your budget.</li>
					<li>Safety First: We prioritize safety by identifying and mitigating potential issues, optimizing traffic flow, and ensuring effective fire exits.</li>
					<li>Rapid Response: Our site plan designers are available 24/7, ready to implement changes and present new options within hours, not days.</li>
				</ul>

				<Text
					mt="20px"
				>Our site plans can be as detailed as you require, covering everything from basic outlines and infrastructure locations to intricate designs for ingress/egress and cable runs. With Viking Events, you can trust that your event will be thoughtfully designed, ensuring a seamless and memorable experience for all.</Text>

			</Section>

			<ContactCTA />

		</Page>

    )

};

// Export Component
export default EventDesign;
