// Imports
import React from 'react';
import PropTypes from 'prop-types';

// Components
import { Flex } from '@radix-ui/themes';

// Cover component
const Cover = (props) => {

	// Return component
	return (

        <Flex
            width="100%"
            height="100%"
            className={(props.canHover) ? "background-dark-grey-trans-hover" : "background-dark-grey-trans"}
            justify="center"
            align="center"
            direction="column"
            position="absolute"
        >

            {props.children}

        </Flex>

    )

};

// Props
Cover.propTypes = {
    canHover: PropTypes.bool,
}

// Export Component
export default Cover;
