// Imports
import React from 'react';
import PropTypes from 'prop-types';

// Components
import { Button } from '@radix-ui/themes';

// PrimaryButton component
const PrimaryButton = (props) => {

    // Work out size
    let size = "3";
    if (props.isLarge) {
        size = "4";
    }
    if (props.isSmall) {
        size = "2";
    }

	// Return component
	return (

		<Button
            loading={props.loading || false}
            onClick={props.onClick}
            className="background-primary-hover"
            size={size}
        >{props.children}</Button>

    )

};

// Props
PrimaryButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    isLarge: PropTypes.bool,
    isSmall: PropTypes.bool,
}

// Export Component
export default PrimaryButton;
