// Imports
import React from 'react';

// Components
import { Text } from '@radix-ui/themes';
import Page from './../../elements/Page';
import Section from './../../elements/Section';
import ContactCTA from '../../sections/ContactCTA';

// EMPSag component
const EMPSag = () => {

	// Return component
	return (

		<Page
			title="EMP & SAG"
			description="We understand that event management plans (EMPs) and safety advisory groups (SAGs) are critical components of any successful live event in the United Kingdom."
			pathname="services/emp-sag"
			heroImage="photos/general/general-1.png"
		>

			<Section>

				<Text
					mt="20px"
				>At Viking Events, we recognize that comprehensive Event Management Plans (EMPs) and effective Safety Advisory Groups (SAGs) are vital to the success of any live event in the UK. Our team is dedicated to guiding you through these critical processes, ensuring that every aspect of your event is meticulously planned and executed.</Text>

				<Text
					mt="20px"
				>Key components of our EMP and SAG services include:</Text>

				<ul>
					<li>Tailored EMP Development: We collaborate closely with clients to create bespoke EMPs that comply with all relevant UK legislation and guidelines, addressing the unique needs of each event.</li>
					<li>Expert SAG Engagement: Navigating the SAG process can be complex. Our experienced team guides you through potential challenges, working with local authorities and emergency services to ensure seamless coordination.</li>
					<li>Comprehensive Safety Planning: We assess potential hazards and risks, developing effective mitigation measures to ensure the safety of all attendees, crew, and contractors.</li>
					<li>Holistic Event Management: Our EMPs cover all facets of your event, from logistics and security to catering and entertainment, helping you realize your vision while adhering to safety regulations.</li>
				</ul>

				<Text
					mt="20px"
				>At Viking Events, our commitment to EMPs and SAGs is integral to delivering exceptional events that are both enjoyable and secure for everyone involved. If you’re looking for a dedicated team that prioritizes safety and compliance, we invite you to contact us today to learn more about our event management services. Together, we can create a successful and safe event experience for all!</Text>

			</Section>

			<ContactCTA />

		</Page>

    )

};

// Export Component
export default EMPSag;
