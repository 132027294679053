// Imports
import React, { useState } from 'react';

// Hooks
import { useIsMobile } from '../../../hooks/useIsMobile';

// Components
import { Text, Heading, Flex } from '@radix-ui/themes';
import Page from './../../elements/Page';
import Section from './../../elements/Section';
import ContactCTA from '../../sections/ContactCTA';
import Image from './../../elements/Image';
import Cover from './../../elements/Cover';
import HireModal from './../../sections/HireModal';

// Utils
import pageScroll from '../../../utils/pageScroll';

// Hire component
const Hire = () => {

	// Is Mobile
	const isMobile = useIsMobile();

	// State
	const [modalIsVisible, setModalIsVisible] = useState(false);
	const [modalContent, setModalContent] = useState(null);

	// Hire Items
	const hireItems = [
		{
			title: 'Pit Barrier',
			image: "photos/general/stage-1-760.png?w=760",
			content: [
				{
					type: "p",
					content: "At Viking Events, we are proud to offer our bespoke Pit Barrier, designed specifically to meet the needs of the live entertainment industry. Engineered for efficiency and safety, our Pit Barrier can be set up quickly by just two people, ensuring that you can focus on delivering an exceptional event without unnecessary delays."
				},
				{
					type: "p",
					content: "Our inventory includes 1m straight sections, variable angle corners, and 1m cable sections, providing you with the flexibility to create a secure and effective pit perimeter for any venue. Whether you’re managing a bustling festival or a dynamic concert, our custom Pit Barrier offers the durability and adaptability you need to keep both performers and audiences safe. Contact us today to learn more about how our Pit Barrier can enhance your event!"
				}
			],
			gallery: []
		},
		{
			title: 'Scaffolding',
			image: "photos/coming-soon-760.png?w=760",
			content: [
				{
					type: "p",
					content: "At Viking Events, our growing inventory of scaffolding is designed to meet the diverse needs of any event setup. With a robust selection of 21ft, 16ft, 6ft, and 4ft poles, we provide the versatility required for both large-scale festivals and smaller gatherings. Our scaffolding solutions are not only reliable but also adaptable, allowing you to create safe and effective structures tailored to your specific event requirements."
				},
				{
					type: "p",
					content: "In addition to our extensive range of poles, we stock a comprehensive array of accessories, including swivel clamps, 90-degree clamps, and couplers. This ensures you have everything necessary to build sturdy and secure frameworks, regardless of the complexity of your design. Trust Viking Events to deliver high-quality scaffolding that supports your vision—reach out to us today to discuss your hire needs!"
				}
			],
			gallery: []
		},
		{
			title: "Artist Dressing Rooms",
			image: "photos/general/artist-dressing-rooms-760.png?w=760",
			content: [
				{
					type: "p",
					content: "At Viking Events, we believe that artists deserve a comfortable and well-equipped space to prepare for their performances. Our comprehensive range of Artist Dressing Room equipment is designed to create a welcoming environment that meets all their needs. From plush sofas to keep them relaxed, to air conditioning units for the perfect climate, we provide everything necessary to ensure artists feel at home before they take the stage."
				},
				{
					type: "p",
					content: "Our inventory includes essential items like water dispensers, tables, clothes rails, mirrors, fridges, and coffee machines, making it easy for artists to refresh and recharge. Whether you’re hosting a major festival or an intimate concert, our dressing room setups are customizable to fit any venue. Elevate the artist experience at your next event—contact us today to learn more about our dressing room equipment hire!"
				}
			],
			gallery: [
				"photos/artist-dressing-rooms/1.png",
				"photos/artist-dressing-rooms/2.png",
				"photos/artist-dressing-rooms/3.png",
				"photos/artist-dressing-rooms/4.png",
				"photos/artist-dressing-rooms/5.png",
				"photos/artist-dressing-rooms/6.png",
				"photos/artist-dressing-rooms/7.png",
				"photos/artist-dressing-rooms/8.png",
				"photos/artist-dressing-rooms/9.png",
				"photos/artist-dressing-rooms/10.png",
				"photos/artist-dressing-rooms/11.png",
				"photos/artist-dressing-rooms/12.png",
				"photos/artist-dressing-rooms/13.png",
				"photos/artist-dressing-rooms/14.png",
			]
		},
		{
			title: "Plant & Machinery",
			image: "photos/coming-soon-760.png?w=760",
			content: [
				{
					type: "p",
					content: "At Viking Events, we offer a specialized range of Plant & Machinery hire to ensure your projects run smoothly and efficiently. Our fleet includes JCB Beaverpacks, each equipped with handheld hydraulic breakers and custom tooling specifically designed for rapid installation of 6ft scaffolding tubes (putlocks). This powerful combination allows for quick and effective groundwork, enabling you to set up your event with minimal downtime."
				},
				{
					type: "p",
					content: "In addition to our JCB Beaverpacks, we collaborate closely with select suppliers to provide our clients with excellent deals on essential machinery, including 4x4 buggies, forklifts, telehandlers, and cherry pickers. Our commitment to quality and affordability ensures that you have the right tools at your disposal, tailored to the demands of your event. Reach out to us today to explore our Plant & Machinery hire options and discover how we can support your next project!"
				}
			],
			gallery: []
		},
		{
			title: "Signage",
			image: "photos/coming-soon-760.png?w=760",
			content: [
				{
					type: "p",
					content: "At Viking Events, we recognize the importance of clear and effective signage at any event. That’s why we stock a wide range of essential event signage, including indicators for Fire Exits, Toilets, Medical assistance, Welfare facilities, and Water stations. Our goal is to ensure that your attendees navigate the venue with ease and confidence."
				},
				{
					type: "p",
					content: "In addition to our standard signage options, we offer custom signage tailored to meet the unique needs of each event. Our signage solutions are designed for quick and straightforward setup, featuring a sturdy frame made up of a 16ft scaff pole, two custom 4ft scaff poles, a putlock, and a few clamps. The signage itself easily clips onto the 4ft scaff poles, allowing you to create a professional and organized environment in no time. Contact us today to discover how our signage hire can enhance your next event!"
				}
			],
			gallery: []
		},
		{
			title: "Festival Flags",
			image: "photos/general/site-1-760.png?w=760",
			content: [
				{
					type: "p",
					content: "At Viking Events, our vibrant stock of festival flags is continually expanding, featuring a variety of eye-catching designs to elevate the atmosphere of any event. Each flag is available in a range of bright colors, making them the perfect addition to any festival site. These flags not only enhance the visual appeal of your venue but also help create a festive and welcoming environment for attendees."
				},
				{
					type: "p",
					content: "Our festival flags are designed to capture attention and add a touch of excitement to your event. Whether you're looking to establish brand visibility or simply want to create a lively ambiance, our diverse selection ensures you’ll find the perfect flags to fit your theme. Contact us today to explore our collection and discover how our festival flags can bring your event to life!"
				}
			],
			gallery: []
		},
		{
			title: "Event Furniture",
			image: "photos/coming-soon-760.png?w=760",
			content: [
				{
					type: "p",
					content: "At Viking Events, we understand that event furniture plays a crucial role in creating a comfortable and inviting atmosphere. While our inventory is tailored to the unique needs of each client, we do keep a selection of picnic benches in stock. These sturdy and stylish benches are a fantastic addition to any festival site, providing attendees with a place to sit, relax, and enjoy the music and lively ambiance."
				},
				{
					type: "p",
					content: "For all other event furniture needs, we pride ourselves on our custom solutions. We either build pieces specifically for your event or collaborate closely with trusted suppliers to ensure we deliver exactly what you’re looking for. Whether you need seating, tables, or other furnishings, our team is dedicated to helping you create the perfect environment for your guests. Contact us today to discuss your event furniture requirements and let us help you enhance your next event!"
				}
			],
			gallery: []
		},
		{
			title: "Festoon",
			image: "photos/coming-soon-760.png?w=760",
			content: [
				{
					type: "p",
					content: "At Viking Events, we offer an impressive stock of festoon lighting, featuring kilometers of strands adorned with vibrant plastic LED bulbs. This stunning lighting solution is the perfect way to create a warm and inviting ambiance at any event. As the sun sets, our festoon lights transform the atmosphere, casting a beautiful glow that enhances the overall experience for attendees."
				},
				{
					type: "p",
					content: "Versatile and easy to install, our festoon lights can be hung in a variety of creative ways—from stages and buildings to flowing above crowds on scaffolding and more. Whether you’re aiming for a whimsical touch at a festival or a stylish backdrop for a private event, our festoon lighting adds that special element that makes your gathering unforgettable. Contact us today to explore our festoon options and discover how we can illuminate your next event!"
				}
			],
			gallery: []
		},
		{
			title: "Radios",
			image: "photos/general/safety-1-760.png?w=760",
			content: [
				{
					type: "p",
					content: "At Viking Events, we provide top-of-the-range Motorola radios to ensure seamless communication across your event. Our radios are designed to meet the demands of any environment, whether you’re working in the site office or right in front of the speakers. With a wide range of microphone and earpiece combinations, you can easily hear and communicate without missing a beat, keeping your team connected no matter where they are."
				},
				{
					type: "p",
					content: "In addition to our high-quality radios, we offer various antennas, charging stations, and essential accessories to support your communication needs. We can also program the radios to suit your specific requirements, including setting priority for certain radios to ensure that critical messages can be communicated swiftly and clearly in the event of an emergency. With our reliable radio hire services, you can focus on delivering a successful event while we take care of your communication needs. Contact us today to learn more!"
				}
			],
			gallery: []
		},
	]

	// Open modal
	const openModal = (item) => {
		setModalContent(item);
		setModalIsVisible(true);
		pageScroll.stop();
	}

	// Close modal 
	const closeModal = () => {
		setModalIsVisible(false);
		setModalContent(null);
		pageScroll.start();
	}

	// Return component
	return (

		<Page
			title="Hire"
			description="Viking Events provides essential equipment and skilled professionals for any event, from festivals to corporate gatherings, ensuring your success."
			pathname="hire"
			heroImage="photos/general/hire-1.png"
		>

			<Section>

				<Text
					mt="20px"
				>At Viking Events, we understand that the success of any event hinges on having the right equipment and skilled professionals at your side. Our comprehensive hire services are designed to meet the diverse needs of event organizers, from festivals to corporate gatherings. Whether you're in need of high-quality machinery, customized containers, or a dedicated crew, we have the resources and expertise to bring your vision to life.</Text>

				<Text
					mt="20px"
				>We pride ourselves on our exceptional customer service and our commitment to delivering tailored solutions that exceed expectations. With our extensive inventory and flexible hire options, you can trust us to provide the support you need, right when you need it. Let us help you elevate your event—contact us today to discuss your hire requirements and discover how Viking Events can make your next project a resounding success!</Text>

			</Section>

			<Section noPaddingTop>

				<Heading>What We Stock</Heading>

				<Flex
					width="100%"
					minHeight="100px"
					overflowX="hidden"
					overflowY="auto"
					wrap="wrap"
				>

					{hireItems.map((item, index) => (

						<Flex
							width={isMobile ? "100%" : "380px"}
							height={isMobile ? "100%" : "380px"}
							ml={isMobile ? "0px" : (index % 2) ? '40px' : '0'}
							mt="40px"
							position="relative"
							key={index}
							className="cursor-pointer"
							onClick={() => { openModal(item) }}
						>

							<Image
								src={item.image}
								alt={item.title}
								isSquare
							/>

							<Cover canHover>

								<Heading
									className={isMobile ? "colour-white font-family-black text-align-center" : "colour-white font-family-black"}
								>{item.title}</Heading>

							</Cover>

						</Flex>

					))}

				</Flex>

			</Section>

			<ContactCTA />

			{(modalIsVisible && modalContent) && 
				<HireModal
					content={modalContent}
					onClose={closeModal}
				/>
			}

		</Page>

    )

};

// Export Component
export default Hire;
