// Imports
import React, { Fragment, useState, useEffect } from 'react';

// Hooks
import { useIsMobile } from '../../../hooks/useIsMobile';

// Components
import { Flex, Text, Heading, Badge, Box } from '@radix-ui/themes';
import Page from './../../elements/Page';
import Section from './../../elements/Section';
import Image from '../../elements/Image';
import PrimaryButton from '../../buttons/PrimaryButton';

// Utils
import api from '@vikingevents/viking-fe-util-api';
import spinner from '@vikingevents/viking-fe-util-spinner';
import defaults from '../../../utils/defaults';

// Team component
const Team = () => {

	// Is mobile
	const isMobile = useIsMobile();

	// State
	const [teamMembers, setTeamMembers] = useState([]);

	// Sort team
	const sortTeam = (team) => {

		// Sort by order
		let sorted = team.sort((a, b) => {
			return Number(a.order) - Number(b.order);
		});

		return sorted;

	}

	// Get team members
	useEffect(() => {

		const getTeamMembers = async () => {

			// Show spinner
			spinner.show();
			await api.send({
                method: "GET",
                cacheKey: defaults.consts.CACHE_KEY,
                sessionKey: defaults.consts.SESSION_KEY,
				cacheableEndpoints: defaults.consts.CACHEABLE_ENDPOINTS,
                api: "viking_main",
                endpoint: "/team",
				requiresAuth: false
            }).then((response) => {

				// Hide spinner
				spinner.hide();

				// Set team members
				setTeamMembers(sortTeam(response.data.team));

			}).catch((error) => {
				console.log(error);
				// Hide spinner
				spinner.hide();

			});

		}

		// Get team members
		getTeamMembers();

	}, []);

	// Contact button clicked
	const contactButtonClicked = (email) => {
		
		// Open up new email
		let link = `mailto:${email}`;
		window.location.href = link;

	}

	// Return component
	return (

		<Page
			title="Our Team"
			description="Meet the Viking team. These are the people that make everything happen and make dreams become realities."
			pathname="team"
			heroImage="photos/general/team-2.png"
		>

			<Section>

				<Flex
					width="100%"
					minHeight="300px"
					wrap="wrap"
					overflowX="hidden"
					overflowY="auto"
					justify={isMobile ? "center" : "start"}
				>

					{teamMembers.map((member, index) => (
					
						<Flex
							width="380px"
							minHeight="300px"
							overflowX="hidden"
							overflowY="auto"
							key={index}
							className="background-very-light-grey"
							shrink="0"
							ml={isMobile ? "0px" : (index % 2 === 0) ? "0px" : "40px"}
							mb="40px"
							direction="column"
							pb="40px"
						>

							<Flex
								width="100%"
								height="240px"
								overflow="hidden"
								justify="center"
								align="center"
								className="background-primary"
							>
								{(member.image !== "") &&
									<Image 
										src={`${member.image.file}?w=760`} 
										alt={member.name} 
										isWide
										isMainBucket
									/>
								}	
							</Flex>	

							<Heading
								className="font-family-black font-size-22"
								mt="20px"
								ml="20px"
							>{member.name}</Heading>

							<Text
								className="font-weight-700"
								ml="20px"
								mt="5px"
							>{member.title}</Text>

							<Text
								ml="20px"
								mr="20px"
								mt="10px"
							>{member.bio}</Text>

							{(member.hobbies.length > 0) &&

								<Fragment>
								
									<Text
										className="font-weight-700 font-size-14"
										ml="20px"
										mt="10px"
									>Hobbies</Text>	

									<Flex
										ml="20px"
										mr="20px"
										mt="10px"
										wrap="wrap"
									>

										{member.hobbies.map((hobby, index) => (

											<Badge 
												color="orange"
												key={index}
												mr="10px"
												mb="10px"
											>{hobby}</Badge>

										))}

									</Flex>	

								</Fragment>
							
							}

							{(member.email !== "") &&

								<Box
									ml="20px"
									mr="20px"
									mt="20px"
								>

									<PrimaryButton
										onClick={() => { contactButtonClicked(member.email) }}
										isSmall
									>Contact {member.name}</PrimaryButton>

								</Box>

							}

						</Flex>
					
					))}

				</Flex>

			</Section>

		</Page>

    )

};

// Export Component
export default Team;

