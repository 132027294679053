// Imports
import React from 'react';

// Components
import { Text, Heading } from '@radix-ui/themes';
import Page from './../../elements/Page';
import Section from './../../elements/Section';
import DarkButton from './../../buttons/DarkButton';

// Careers component
const Careers = () => {

	// Freelance button clicked
	const freelanceClicked = () => {
		
		// Open up new email
		let name = "info";
		let domain = "vikingeventsgroup.com";
		let subject = "Interested in Freelance Work";
		let body = `********************** %0D%0A
Thank you for your interest in working with us! %0D%0A
Please replace this text with a little introduction of yourself and your experience. %0D%0A
If you have a CV or portfolio, please attach it to this email. %0D%0A
We look forward to hearing from you! %0D%0A
**********************`;
		let link = `mailto:${name}@${domain}?subject=${subject}&body=${body}`;
		window.location.href = link;

	}

	// Return component
	return (

		<Page
			title="Careers"
			description="As a rapidly expanding company in the events sector, we are continuously seeking to enlarge our team of skilled professionals."
			pathname="careers"
			heroImage="photos/general/team-1.png"
		>

			<Section>

				<Text
					mt="20px"
				>At Viking Events, we are a rapidly expanding company in the events sector, and we are continuously looking to grow our team of skilled professionals. Here’s what we offer:</Text>

				<ul className="ul-orange">
					<li>Comprehensive Training: We provide all necessary training, ensuring our crew is equipped to handle any situation.</li>
					<li>Personal Protective Equipment: We supply essential PPE to keep our staff safe.</li>
					<li>Tools and Resources: All the tools needed for effective performance are provided.</li>
					<li>Competitive Compensation: We are recognized as one of the highest-paying organizations in the industry, reflecting our commitment to staff well-being.</li>
					<li>Career Development: We offer recognized training packages for those interested in advancing their careers within the events industry.</li>
				</ul>

				<Text
					mt="20px"
				>We encourage applications from professionals of all skill sets during peak periods and welcome individuals passionate about joining our team, especially during the off-season. Key points include:</Text>

				<ul className="ul-orange">
					<li>Seasonal Operations: We cover vast distances and are always recruiting talented individuals.</li>
					<li>Location Flexibility: While our primary base is in the South of the UK, we operate nationwide.</li>
					<li>Dynamic Work Environment: If you thrive in a challenging setting and have an eye for detail, we want to hear from you!</li>
				</ul>

				<Text
					mt="20px"
				>Join us in delivering top-notch services to our clients in a supportive and inclusive work environment where everyone can grow.</Text>

				<Heading
					mt="40px"
					mb="20px"
					className="font-family-black"
				>Our Opportunities:</Heading>

				<Text>We currently have no opportunities available.</Text>

			</Section>
		
			<Section
				isPrimary
			>

				<Heading
					className="font-family-black font-size-32 colour-white"
					mb="40px"
				>Freelance</Heading>

				<Text
					mb="40px"
					className="colour-white"
				>We are constantly seeking talented professionals to join us on a variety of exciting projects. If you’re interested in becoming part of our project teams and contributing to the incredible experiences we create, we’d love to hear from you. Get in touch with us today!</Text>

				<DarkButton
					onClick={freelanceClicked}
				>Are you interested?</DarkButton>

			</Section>

		</Page>

    )

};

// Export Component
export default Careers;
