// Imports
import React, { useState } from 'react';

// Hooks
import { useIsMobile } from '../../../hooks/useIsMobile';

// Components
import { Text, Heading, Flex } from '@radix-ui/themes';
import Page from './../../elements/Page';
import Section from './../../elements/Section';
import ContactCTA from '../../sections/ContactCTA';
import Image from '../../elements/Image';
import Cover from '../../elements/Cover';
import HireModal from '../../sections/HireModal';

// Utils
import pageScroll from '../../../utils/pageScroll';

// Structures component
const Structures = () => {

	// Is Mobile
	const isMobile = useIsMobile();

	// State
	const [modalIsVisible, setModalIsVisible] = useState(false);
	const [modalContent, setModalContent] = useState(null);

	// Structure Items
	const structureItems = [
		{
			title: 'The Arch',
			image: "photos/coming-soon-760.png?w=760",
			content: [
				{
					type: "p",
					content: "Introducing The Arch from Viking Events—a stunning focal point for any event. Crafted from durable steel RSJs, this impressive structure can be customized to meet your specific needs. Clients can choose between a standard VIP sign or a bespoke sign tailored to their branding or event theme. The Arch serves as a striking entrance that sets the tone for your event from the moment guests arrive."
				},
				{
					type: "p",
					content: "What sets The Arch apart is its versatility; you can select custom paint colors to match your event’s aesthetic, ensuring it seamlessly integrates into your overall design. As day turns to night, The Arch transforms further with illuminating features that enhance its presence and create a captivating atmosphere. Whether it’s for a festival, corporate event, or private celebration, The Arch is designed to make a memorable impact. Contact us today to discuss how The Arch can elevate your next event!"
				}
			],
			gallery: []
		},
		{
			title: 'Brewery Seating Pods',
			image: "photos/coming-soon-760.png?w=760",
			content: [
				{
					type: "p",
					content: "At Viking Events, our Brewery Seating Pods offer a unique and inviting space for attendees to relax and enjoy their experience. Measuring 8ft x 8ft, these semi-open containers are designed with a rustic brewery aesthetic that adds charm and character to any event. The inviting atmosphere encourages social interaction while providing a comfortable spot to unwind."
				},
				{
					type: "p",
					content: "Inside each pod, you'll find tables and chairs crafted from repurposed beer kegs, giving the seating a distinctive touch that enhances the overall brewery theme. These pods not only provide a cozy environment for guests to gather but also serve as an eye-catching feature that complements your event’s ambiance. Perfect for festivals, markets, or private gatherings, our Brewery Seating Pods create a memorable experience for all. Contact us today to learn more about how these unique seating options can enhance your next event!"
				}
			],
			gallery: []
		}
	]

	// Open modal
	const openModal = (item) => {
		setModalContent(item);
		setModalIsVisible(true);
		pageScroll.stop();
	}

	// Close modal 
	const closeModal = () => {
		setModalIsVisible(false);
		setModalContent(null);
		pageScroll.start();
	}

	// Return component
	return (

		<Page
			title="Structures"
			description="Viking Events’ Structures service creates custom metal and wooden fabrications, from unique stages to bespoke installations, bringing your ideas to life."
			pathname="structures"
			heroImage="photos/general/hire-1.png"
		>

			<Section>

				<Text
					mt="20px"
				>At Viking Events, our Structures service is dedicated to transforming your creative visions into reality through custom fabrication of both metal and wooden structures. Whether you’re looking to create stunning architectural features, unique stages, or bespoke installations, our skilled team is here to bring your ideas to life. We pride ourselves on our ability to work closely with clients, ensuring that every detail aligns with their vision and requirements.</Text>

				<Text
					mt="20px"
				>In addition to our custom fabrication services, we also offer a selection of structures available for hire. This means you can easily enhance your event with high-quality, ready-to-install solutions that fit seamlessly into your overall design. Our structures are designed to meet the diverse needs of any event, big or small. Trust Viking Events to provide the innovative and reliable structures you need to create an unforgettable experience. Contact us today to discuss your project and discover how we can help you realize your event's potential!</Text>

			</Section>

			<Section noPaddingTop>

				<Heading>What We Stock</Heading>

				<Flex
					width="100%"
					minHeight="100px"
					overflowX="hidden"
					overflowY="auto"
					wrap="wrap"
				>

					{structureItems.map((item, index) => (

						<Flex
							width={isMobile ? "100%" : "380px"}
							height={isMobile ? "100%" : "380px"}
							ml={isMobile ? "0px" : (index % 2) ? '40px' : '0'}
							mt="40px"
							position="relative"
							key={index}
							className="cursor-pointer"
							onClick={() => { openModal(item) }}
						>

							<Image
								src={item.image}
								alt={item.title}
							/>

							<Cover canHover>

								<Heading
									className={isMobile ? "colour-white font-family-black text-align-center" : "colour-white font-family-black"}
								>{item.title}</Heading>

							</Cover>

						</Flex>

					))}

				</Flex>

			</Section>

			<ContactCTA />

			{(modalIsVisible && modalContent) && 
				<HireModal
				content={modalContent}
				onClose={closeModal}
				/>
			}

		</Page>

    )

};

// Export Component
export default Structures;
