// Start
const start = () => {
    document.body.classList.remove("no-scroll");
};

// Stop
const stop = () => {
    document.body.classList.add("no-scroll");
}

export default {
    start: start,
    stop: stop
}