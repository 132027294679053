// Imports
import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from "react-helmet";

// Meta component
const Meta = (props) => {

    // Work out title
    let title = "Viking Events";
    if (props.title !== "Home") {
        title = `Viking Events | ${props.title}`;
    }

    //Work out url
    let url = "https://vikingeventsgroup.com";
    if (props.pathname !== "home") {
        url = `https://vikingeventsgroup.com/${props.pathname}`;
    }

    // Work out image
    let image = props.image;
    if (props.image === "default") {
        image = "https://viking-assets-public.s3.amazonaws.com/branding/logo-white.png";
    }

	// Return component
	return (

		<Helmet>

            <title>{title}</title>
            <meta name="description" content={props.description} />

            <link rel="canonical" href={url} />

            <meta property="og:title" content={title} />
            <meta property="og:description" content={props.description} />
            <meta property="og:url" content={url} />
            <meta property="og:image" content={image} />


            <meta name="twitter:title" content={title}/>
            <meta name="twitter:description" content={props.description} />

        </Helmet>

    )

};

Meta.propTypes = {
    title: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired
}

// Export Component
export default Meta;
