// Imports
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// Hooks
import { useIsMobile } from '../../../hooks/useIsMobile';

// Utils
import api from '@vikingevents/viking-fe-util-api';
import spinner from '@vikingevents/viking-fe-util-spinner';
import defaults from '../../../utils/defaults';

// Assets
import LogoWhite from '../../../assets/logos/logo-white.png';

// Components
import { Flex, Text, Heading } from '@radix-ui/themes';
import Testimonials from '../../sections/Testimonials';
import LearnMore from '../../sections/LearnMore';
import ContactCTA from '../../sections/ContactCTA';
import Meta from '../../Meta';
import Image from '../../elements/Image';
import Cover from '../../elements/Cover';

// Home component
const Home = () => {

	// Is Mobile
	const isMobile = useIsMobile();
	const isMobileHero = useIsMobile(1460);

	const navigate = useNavigate();

	// State
	const [recentProjects, setRecentProjects] = useState([]);

	// Get recent projects
	useEffect(() => {

		const getRecentProjects = async () => {

			spinner.show();

			await api.send({
                method: "GET",
                cacheKey: defaults.consts.CACHE_KEY,
                sessionKey: defaults.consts.SESSION_KEY,
				cacheableEndpoints: defaults.consts.CACHEABLE_ENDPOINTS,
                api: "viking_main",
                endpoint: "/recent-projects",
                requiresAuth: false
            }).then((response) => {

				setRecentProjects(response.data.projects);
				spinner.hide();

			}).catch((error) => {

				spinner.hide();

			});

		}

		getRecentProjects();

	}, []);

	// Navigate to recent project
	const navigateToRecentProject = (project) => {

		navigate(`/recent-project/${project._id}?title=${project.title}`);

	}

	// Return component
	return (

		<Flex
			width="100%"
			minHeight="100px"
			overflowY="auto"
			overflowX="hidden"
			justify="start"
			align="start"
			direction="column"
		>

			<Meta 
				title="Home" 
				description="Your top trump. The Ace in your hand. We are the people who will be by your side, day or night, to craft your vision into a physical reality." 
				pathname="home" 
				image="default" 
			/>

			<Flex
				width="100%"
				height="100vh"
				overflow="hidden"
				justify="center"
				align="center"
				position="relative"
			>

				<Image
					src="photos/general/show-3.png"
					alt="Audley End Festival 2022"
					isWide={isMobileHero ? false : true}
					isTall={isMobileHero ? true : false}
				/>

				<Cover>

					<Heading
						className={isMobile ? "font-family-black colour-white font-size-80 text-align-center font-line-height-120" : "font-family-black colour-white font-size-110"}
						mb={isMobile ? "50px" : "100px"}
					>We Are Viking</Heading>

					<Heading
						className="colour-white font-size-24 font-weight-500"
					>We Do Things Differently</Heading>

				</Cover>

			</Flex>

			<Flex
				width="100%"
				height="700px"
				className="background-primary"
				justify="center"
				align="center"
				direction={isMobile ? "column" : "row"}
			>

				<Flex
					width={isMobile ? "280px" : "360px"}
					minHeight="10px"
					overflowY="auto"
					overflowX="hidden"
					justify="center"
					align="center"
				>
					<Image 
						asset={LogoWhite} 
						alt="Viking Logo" 
					/>
				</Flex>

				<Flex
					width={isMobile ? "90%" : "700px"}
					minHeight="10px"
					overflowY="auto"
					overflowX="hidden"
					ml={isMobile ? "0px" : "80px"}
					direction="column"
					mt={isMobile ? "20px" : "0px"}
				>

					<Heading
						className={isMobile ? "font-family-black font-size-32 colour-white text-align-center" : "font-family-black font-size-32 colour-white"}
						mb="20px"
					>Who Are We?</Heading>

					<Text
						className={isMobile ? "colour-white text-align-center" : "colour-white"}
					>Your top trump. The Ace in your hand. We are the people who will be by your side, day or night, to craft your vision into a physical reality. Growing from the ground up, our management team is chosen based on their real-world experience in our field, not just a qualification. We pride ourselves on our unparalleled work ethic, ensuring we go above and beyond to make your event a success.</Text>

				</Flex>

			</Flex>

			<Flex
				width="100%"
				minHeight="10px"
				overflowY="auto"
				overflowX="hidden"
				className="background-white"
				justify="center"
				align="center"
				direction="column"
				pt="120px"
				pb="120px"
			>

				<Heading
					className="font-family-black font-size-32"
				>Recent Projects</Heading>

				{recentProjects.length === 0 &&
					<Text
						mt="40px"
					>Coming soon...</Text>
				}

				{recentProjects.length > 0 &&

					<Flex
						minWidth="10px"
						minHeight="400px"
						overflowY="hidden"
						overflowX="auto"
						justify="center"
						align="center"
						mt="80px"
						wrap="wrap"
						direction={isMobile ? "column" : "row"}
					>

						{recentProjects.map((project, index) => (

							<Flex
								key={index} 
								onClick={() => { navigateToRecentProject(project) }}
								width={isMobile ? "340px" : "400px"}
								height={isMobile ? "340px" : "400px"}
								ml={isMobile ? "0px" : (index === 0) ? "0px" : "40px"}
								overflow="hidden"
								justify="center"
								align="center"
								className="cursor-pointer"
								position="relative"
								mt="40px"
							>

								<Image
									src={`${project.image}?w=800`}
									alt={project.title}
									isSquare
									isMainBucket
								/>

								<Cover
									canHover
								>

									<Heading
										className="font-family-black font-size-24 colour-white text-align-center"
										mb="30px"
									>{project.title}</Heading>

									<Text
										className="colour-white text-align-center"
									>{project.updatedAt.display}</Text>

								</Cover>

							</Flex>

						))}

					</Flex>

				}

			</Flex>

			<Testimonials />

			<LearnMore content={{
				image: "photos/general/team-1-900.png",
				title: "Crew",
				text: "At Viking Events, we take pride in investing not just money, but also time in our crew. Through official training programs (NPORS, IPAF, IOSH, NeBOSH, ACT CT, etc.) and hands-on experience with our senior managers, we ensure our team is continuously honing their skills. Our crew is not only presentable and hard-working, but also proactive and adaptable. They arrive punctually, equipped with the right qualifications and tools for every task. No challenge is too mundane, and no request too ambitious—we thrive on pushing boundaries. Our core staff works with us year-round across our diverse portfolio, gaining a deep understanding of Viking’s standards and how we get the job done.",
				link: "/services/crew"
			}} />

			<LearnMore isDark isFlipped content={{
				image: "photos/mojo/mojo-1-900.png",
				title: "Hire",
				text: "At Viking Events, we understand that the success of any event hinges on having the right equipment and skilled professionals at your side. Our comprehensive hire services are designed to meet the diverse needs of event organizers, from festivals to corporate gatherings. Whether you're in need of high-quality machinery, customized containers, or a dedicated crew, we have the resources and expertise to bring your vision to life.",
				link: "/hire"
			}} />

			<LearnMore content={{
				image: "photos/general/site-1-900.png",
				title: "Event Design",
				text: "At Viking Events, we believe that the foundation of any successful event lies in the careful layout and design of the site. Thoughtful planning from the outset can lead to significant cost savings down the line. Our site plans are crafted and refined by the very teams who will be executing them—professionals with real-world experience in bringing these designs to life. They understand how even the smallest adjustments can impact flow and functionality. This blend of practical knowledge and expertise ensures that your event will benefit from a layout that enhances the overall experience.",
				link: "/services/event-design"
			}}/>

			<LearnMore isDark isFlipped content={{
				image: "photos/general/safety-1-900.png",
				title: "Safety",
				text: "Safety is the cornerstone of everything we do at Viking Events. Our journey began with hands-on experience, allowing us to develop a deep understanding of safety in the unique context of the event industry. Our safety officers are not just clipboard managers; they are seasoned professionals who have risen through the ranks from site crew. They know the intricacies and quirks of events, ensuring that safety is seamlessly integrated into every aspect of our operations.",
				link: "/services/safety"
			}}/>

			<ContactCTA />

		</Flex>

    )

};

// Export Component
export default Home;
