// Imports
import React from 'react';
import PropTypes from 'prop-types';

// Components
import { Flex } from '@radix-ui/themes';
import Meta from './../../Meta';
import PageHero from '../../sections/PageHero';

// Page component
const Page = (props) => {

	// Return component
	return (

		<Flex
			width="100%"
			minHeight="100px"
			overflowY="auto"
			overflowX="hidden"
			justify="start"
			align="center"
			direction="column"
			pt="100px"
		>

			<Meta 
                title={props.title}
                description={props.description}
                pathname={props.pathname}
                image={props.metaImage || "default"}  
            />

			<PageHero 
                title={props.title} 
                image={props.heroImage} 
            />

			{props.children}

		</Flex>

    )

};

// Props
Page.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired,
    metaImage: PropTypes.string,
    heroImage: PropTypes.string.isRequired,
}

// Export Component
export default Page;
