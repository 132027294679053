// Store Consts
const MOBILE_WIDTH = 850;
const CACHE_KEY = "viking_main_cache";
const SESSION_KEY = "viking_main_session";
const CACHEABLE_ENDPOINTS = [];


export default {
    consts: {
        MOBILE_WIDTH: MOBILE_WIDTH,
        CACHE_KEY: CACHE_KEY,
        SESSION_KEY: SESSION_KEY,
        CACHEABLE_ENDPOINTS: CACHEABLE_ENDPOINTS
    }
}

/*

{
        endpoint: "/team",
        type: "localStorage"
    },
    {
        endpoint: "/recent-projects/*",
        type: "localStorage"
    },
    {
        endpoint: "/news/*",
        type: "localStorage"
    },
    {
        endpoint: "/news",
        type: "localStorage"
    },
    {
        endpoint: "/recent-projects",
        type: "localStorage"
    },
    {
        endpoint: "/gallery",
        type: "localStorage"
    }

    */