// Imports
import React from 'react';

// Components
import { Text } from '@radix-ui/themes';
import Page from './../../elements/Page';
import Section from './../../elements/Section';
import ContactCTA from '../../sections/ContactCTA';

// Safety component
const Safety = () => {

	// Return component
	return (

		<Page
			title="Safety"
			description="At Viking Events, we understand that the safety of our staff and attendees is paramount when it comes to any live event."
			pathname="services/safety"
			heroImage="photos/general/safety-1.png"
		>

			<Section>

				<Text
					mt="20px"
				>At Viking Events, the safety of all staff and attendees is our top priority. We understand that a successful live event is built on a foundation of rigorous safety measures, which is why we strive to meet and exceed all UK health and safety regulations.</Text>

				<Text
					mt="20px"
				>Our experienced team is well-versed in the unique challenges of the UK festival and event industry. We collaborate closely with clients to identify potential hazards and mitigate risks, creating tailored risk assessments and safety plans for each event. Key components of our safety approach include:</Text>

				<ul>
					<li>Proactive Risk Management: We take the initiative to identify potential hazards and implement preventive measures before they become issues.</li>
					<li>Collaboration with Authorities: Our team has extensive experience working with local authorities and emergency services to secure the necessary permits and resources, ensuring smooth and safe operations.</li>
					<li>Comprehensive Crew Training: We provide our staff with thorough training on health and safety procedures and equip them with the necessary personal protective equipment (PPE) and tools to perform their work safely.</li>
				</ul>

				<Text
					mt="20px"
				>Our commitment to health and safety is integral to delivering exceptional events that are enjoyable and secure for everyone involved. If you’re looking for a dedicated team that prioritizes safety and compliance, we invite you to contact us today to learn more about our event management services. Together, we can create a safe and memorable experience for all.</Text>

			</Section>

			<ContactCTA />

		</Page>

    )

};

// Export Component
export default Safety;
