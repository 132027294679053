// Imports
import React from 'react';

// Components
import { Text } from '@radix-ui/themes';
import Page from './../../elements/Page';
import Section from './../../elements/Section';
import ContactCTA from '../../sections/ContactCTA';

// Consulting component
const Consulting = () => {

	// Return component
	return (

		<Page
			title="Consulting"
			description="We can provide consultancy services for new and existing festivals and events in the UK."
			pathname="services/consulting"
			heroImage="photos/general/stage-2.png"
		>

			<Section>

				<Text
					mt="20px"
				>At Viking Events, we offer comprehensive consultancy services for both new and existing festivals and events across the UK. Our team of seasoned professionals has a proven track record of delivering successful, safe, and profitable events across various sectors.</Text>

				<Text
					mt="20px"
				>We recognize that every step in the event planning process is crucial to your event's success. That’s why we collaborate closely with our clients to identify their unique needs and develop tailored solutions. Our consultancy services encompass every aspect of event planning, including:</Text>

				<ul>
					<li>Concept Development: From brainstorming initial ideas to refining your vision, we guide you through the creative process.</li>
					<li>Venue Selection & Design: We help you choose the perfect venue and design a layout that enhances the attendee experience.</li>
					<li>Logistics & Operations: Our team ensures that all logistical elements are meticulously planned and executed.</li>
					<li>Health & Safety Guidance: We prioritize safety in all aspects of event management, ensuring compliance with regulations.</li>
					<li>Marketing & Promotion: We assist in developing effective strategies to attract attendees and maximize engagement.</li>
				</ul>

				<Text
					mt="20px"
				>In addition to our focus on safety and profitability, we are committed to sustainability and environmental responsibility. We work with our clients to create sustainable event strategies that minimize waste and reduce the environmental impact of their events.</Text>

				<Text
					mt="20px"
				>Our consultancy services are designed to empower you with the support and expertise needed to create successful and memorable events. Whether you’re a seasoned event organizer or just starting out, we invite you to contact us today to learn how we can help you achieve your event planning goals!</Text>

			</Section>

			<ContactCTA />

		</Page>

    )

};

// Export Component
export default Consulting;
