// Imports
import React from 'react';
import { useNavigate } from 'react-router-dom';

// Hooks
import { useIsMobile } from '../../../hooks/useIsMobile';

// Components
import { Flex, Text } from '@radix-ui/themes';
import Page from '../../elements/Page';
import Section from '../../elements/Section';
import Image from '../../elements/Image';
import Cover from '../../elements/Cover';

// Services component
const Services = () => {

	// Is mobile
	const isMobile = useIsMobile();

	const whatWeDo = [
		{
			title: "Crew",
			link: "/services/crew",
			image: "photos/general/crew-1-760.png?w=760"
		},
		{
			title: "Event Design",
			link: "/services/event-design",
			image: "photos/general/site-1-760.png?w=760"
		},
		{
			title: "Trailers",
			link: "/services/trailers",
			image: "photos/coming-soon-760.png?w=760"
		},
		{
			title: "Safety",
			link: "/services/safety",
			image: "photos/general/safety-1-760.png?w=760"
		},
		{
			title: "EMP & SAG",
			link: "/services/emp-sag",
			image: "photos/general/general-1-760.png?w=760"
		},
		{
			title: "Consulting",
			link: "/services/consulting",
			image: "photos/general/stage-2-760.png?w=760"
		}
	];

	const navigate = useNavigate();

	// Navigate to service
	const navigateToService = (service) => {

		navigate(service.link);

	}

	// Return component
	return (

		<Page
			title="Services"
			description="We absorb the operational stress of planning and running events, leaving you to focus on driving sales."
			pathname="services"
			heroImage="photos/general/show-4.png"
		>

			<Section>

				<Text
					mt="20px"
				>At Viking Events, we take the operational stress out of planning and running your events, allowing you to concentrate on driving sales and maximizing success. We understand the complexities of stakeholder relationships and the importance of managing them effectively to ensure your event thrives for years to come.</Text>

				<Text
					mt="20px"
				>Our dedicated team works tirelessly with suppliers to keep your budget in check, ensuring costs remain low without compromising quality. We pride ourselves on our proactive approach and commitment to excellence, always thinking ahead to anticipate challenges and find solutions.</Text>

				<Text
					mt="20px"
				>Partner with us to experience a team that goes the extra mile. We’re here to make your event not only successful but memorable!</Text>

			</Section>

			<Section>

				<Flex
					width="100%"
					minHeight="300px"
					wrap="wrap"
					overflowX="hidden"
					overflowY="auto"
				>

					{whatWeDo.map((item, index) => (

						<Flex
							key={index} 
							onClick={() => { navigateToService(item); }}
							width="380px"
							height="380px"
							className="background-primary cursor-pointer"
							overflow="hidden"
							justify="center"
							align="center"
							position="relative"
							shrink="0"
							ml={isMobile ? "0px" : (index % 2 === 0) ? "0px" : "40px"}
							mb="40px"
						>

							<Image
								src={`${item.image}?h=760`}
								alt={item.title}
								isSquare
							/>

							<Cover
								canHover
							>

								<Text
									className="font-family-black font-size-22 colour-white"
								>{item.title}</Text>

							</Cover>

						</Flex>

					))}

				</Flex>

			</Section>

		</Page>

    )

};

// Export Component
export default Services;
