import { useEffect, useState } from "react";
import { useClientWidth } from "./useClientWidth";
import defaults from "../utils/defaults";

export const useIsMobile = (minWidth = null) => {
  
    const clientWidth = useClientWidth();
    const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
        if (minWidth !== null) {
            if (clientWidth < minWidth) {
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }
        } else {
            if (clientWidth < defaults.consts.MOBILE_WIDTH) {
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }
        }
    }, [clientWidth]);

    return isMobile;

};