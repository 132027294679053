// Imports
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Components
import { Flex } from '@radix-ui/themes';

// Hooks
import { useIsMobile } from '../../../hooks/useIsMobile';

// Icons
import { Cross1Icon, ChevronLeftIcon, ChevronRightIcon } from '@radix-ui/react-icons';

// Utils
import assets from '@vikingevents/viking-fe-util-assets';

// ContentViewer component
const ContentViewer = React.forwardRef((props, ref) => {

    // Is Mobile
    const isMobile = useIsMobile(910);

    const [activeSlide, setActiveSlide] = useState(2);
    const [galleryVisible, setGalleryVisible] = useState(false);

    // Handle media click
    const handleMediaClick = (mediaIndex) => {
        setActiveSlide(mediaIndex);
        toggleGallery(true);
    };

    // Toggle slide
    const toggleSlide = (isBack) => {

        let minIndex = 0;
        let maxIndex = props.media.length - 1;
        let currentIndex = activeSlide;
        let newSlide = 0;

        if (isBack) {

            if (currentIndex === minIndex) {
                newSlide = maxIndex;
            } else {
                newSlide = currentIndex - 1;
            }

        } else {

            if (currentIndex === maxIndex) {
                newSlide = minIndex;
            } else {
                newSlide = currentIndex + 1;
            }

        }

        setActiveSlide(newSlide);

    };

    // Toggle gallery 
    const toggleGallery = (shouldOpen) => {

        // Set state
        setGalleryVisible(shouldOpen);

    };

	// Return component
	return (

        <Flex
            width={isMobile ? "90%" : "880px"}
            minHeight="10px"
            overflowX="hidden"
            overflowY="auto"
            className="background-white radius-10 content-viewer"
            justify="start"
            align="start"
            direction="column"
            p="40px"
        >

            <RenderElements 
                elements={props.content} 
            />

            {props.media.length > 0 && 
                <Flex
                    width="100%"
                    height="2px"
                    flexShrink="0"
                    justify="center"
                    align="center"
                    mt="40px"
                    mb="40px"
                >
                    <Flex
                        width="70%"
                        height="2px"
                        flexShrink="0"
                        className="background-primary"
                    ></Flex>
                </Flex>
            }

            {props.media.length > 0 && 
            
                <Flex
                    width={isMobile ? "90%" : "800px"}
                    minHeight="10px"
                    overflowX="hidden"
                    overflowY="auto"
                    justify="start"
                    align="start"
                    direction="row"
                    wrap="wrap"
                    flexShrink="0"
                >
                
                    {props.media.map((media, index) => (

                        <Flex
                            width="185px"
                            height="185px"
                            overflow="hidden"
                            mb="20px"
                            className="media-item background-very-light-grey cursor-pointer"
                            justify="center"
                            align="center"
                            key={index}
                            onClick={() => { handleMediaClick(index); }}
                        >

                            {media.type.includes("image") &&
                                <img 
                                    src={`https://viking-assets-main.s3.amazonaws.com/${media.folder}/${media.fileName}`} 
                                    alt={media.alt} 
                                    className="img-full-square"
                                />
                            }

                            {media.type.includes("video") &&
                                <video 
                                    src={`https://viking-assets-main.s3.amazonaws.com/${media.folder}/${media.fileName}`} 
                                    alt={media.alt} 
                                    controls
                                    width="185px"
                                    onClick={(e) => { e.preventDefault(); }}
                                />
                            }

                            {media.type.includes("audio") &&
                                <Flex
                                    width="185px"
                                    minHeight="10px"
                                    overflowX="hidden"
                                    overflowY="auto"
                                    justify="center"
                                    align="center"
                                    pl="10px"
                                    pr="10px"
                                    pt="40px"
                                    pb="40px"
                                >
                                    <audio 
                                        src={`https://viking-assets-main.s3.amazonaws.com/${media.folder}/${media.fileName}`} 
                                        alt={media.alt} 
                                        controls
                                    />
                                </Flex>
                            }

                        </Flex>

                ))}

                </Flex>
            
            }

            {galleryVisible &&

                <Flex
                    width="100%"
                    height="100vh"
                    className="z-index-1000 background-dark-grey-trans"
                    position="fixed"
                    margin="auto"
                    top="0"
                    left="0"
                    right="0"
                    bottom="0"
                    justify="center"
                    align="center"
                >

                    <Flex
                        width="100%"
                        height="100vh"
                        position="relative"
                        className="background-transparent"
                        justify="center"
                        align="center"
                    >

                        <Flex
                            width="32px"
                            height="100vh"
                            position="absolute"
                            margin="auto"
                            left="40px"
                            justify="center"
                            align="center"
                        >
                            <Flex
                                width="32px"
                                height="100px"
                                className="background-primary-hover cursor-pointer"
                                justify="center"
                                align="center"
                                onClick={() => { toggleSlide(true); }}
                            >
                                <ChevronLeftIcon 
                                    className="colour-white"
                                    width="32px"
                                    height="32px"
                                />
                            </Flex>
                        </Flex>

                        {props.media.length > 0 &&

                            props.media.map((media, index) => {

                                if (media.type.includes("image")) {

                                    if (media.dimensions.width > media.dimensions.height) {

                                        return (

                                            <Flex
                                                width={isMobile ? "50%" : "70%"}
                                                minHeight="10px"
                                                overflowX="hidden"
                                                overflowY="auto"
                                                className="background-very-light-grey"
                                                justify="center"
                                                align="center"
                                                display={(activeSlide === index) ? "flex" : "none"}
                                                key={index}
                                            >
        
                                                <img 
                                                    src={`https://viking-assets-main.s3.amazonaws.com/${media.folder}/${media.fileName}`} 
                                                    alt={media.alt}
                                                    className="img-full-width"
                                                />
        
                                            </Flex>
        
                                        );

                                    } else {

                                        return (

                                            <Flex
                                                height={isMobile ? "50%" : "70%"}
                                                minWidth="10px"
                                                overflowY="hidden"
                                                overflowX="auto"
                                                justify="center"
                                                align="center"
                                                display={(activeSlide === index) ? "flex" : "none"}
                                                key={index}
                                            >
        
                                                <img 
                                                    src={`https://viking-assets-main.s3.amazonaws.com/${media.folder}/${media.fileName}`} 
                                                    alt={media.alt}
                                                    className="img-full-height"
                                                />
        
                                            </Flex>
        
                                        );

                                    }

                                }

                                if (media.type.includes("video")) {

                                    return (

                                        <Flex
                                            width={isMobile ? "50%" : "70%"}
                                            minHeight="10px"
                                            overflowX="hidden"
                                            overflowY="auto"
                                            justify="center"
                                            align="center"
                                            display={(activeSlide === index) ? "flex" : "none"}
                                            key={index}
                                        >

                                            <video 
                                                src={`https://viking-assets-main.s3.amazonaws.com/${media.folder}/${media.fileName}`} 
                                                alt={media.alt} 
                                                controls
                                                width="100%"
                                            />

                                        </Flex>
                                        
                                    );

                                }

                                if (media.type.includes("audio")) {

                                    return (
                                        
                                        <Flex
                                            width={isMobile ? "50%" : "70%"}
                                            minHeight="10px"
                                            overflowX="hidden"
                                            overflowY="auto"
                                            className="background-very-light-grey"
                                            justify="center"
                                            align="center"
                                            display={(activeSlide === index) ? "flex" : "none"}
                                            key={index}
                                        >

                                            <audio 
                                                src={`https://viking-assets-main.s3.amazonaws.com/${media.folder}/${media.fileName}`} 
                                                alt={media.alt} 
                                                controls
                                            />

                                        </Flex>
                                        
                                    );

                                }
                                
                            })

                        }

                        <Flex
                            width="32px"
                            height="100vh"
                            position="absolute"
                            margin="auto"
                            right="40px"
                            justify="center"
                            align="center"
                        >
                            <Flex
                                width="32px"
                                height="100px"
                                className="background-primary-hover cursor-pointer"
                                justify="center"
                                align="center"
                                onClick={() => { toggleSlide(false); }}
                            >
                                <ChevronRightIcon 
                                    className="colour-white"
                                    width="32px"
                                    height="32px"
                                />
                            </Flex>
                        </Flex>

                        <Flex
                            width="32px"
                            height="32px"
                            position="absolute"
                            className="background-primary radius-3 cursor-pointer"
                            margin="auto"
                            top="20px"
                            right="20px"
                            justify="center"
                            align="center"
                            onClick={() => { toggleGallery(false); }}
                        >
                            <Cross1Icon 
                                className="colour-white"
                            />
                            
                        </Flex>

                    </Flex>

                </Flex>

            }
            
        </Flex>

    )

});

const RenderElements = ({ elements }) => {
    const renderElement = (element) => {
      try {
        // Validate element structure
        if (
          !element ||
          typeof element !== 'object' ||
          !element.tag ||
          typeof element.tag !== 'string' ||
          !Array.isArray(element.content)
        ) {
          console.error('Invalid element structure:', element);
          return null; // Skip rendering this element
        }
  
        const { tag, attributes = {}, content } = element;
  
        // Render child content recursively or as plain text
        const children = content.map((child, index) =>
          typeof child === 'string' ? (
            child
          ) : (
            <React.Fragment key={index}>{renderElement(child)}</React.Fragment>
          )
        );
  
        // Return the JSX element
        return React.createElement(tag, { ...attributes, key: Math.random() }, children);
      } catch (error) {
        console.error('Error rendering element:', element, error);
        return <p key={Math.random()}>Error rendering element.</p>;
      }
    };
  
    // Handle invalid elements array
    if (!Array.isArray(elements)) {
      console.error('Invalid elements array:', elements);
      return <p>Error: Unable to render elements. Invalid data provided.</p>;
    }
  
    // Map through elements array and render each
    return <>{elements.map((el, index) => renderElement(el))}</>;
  };

// Props
ContentViewer.propTypes = {
    content: PropTypes.array.isRequired,
    media: PropTypes.array
};

// Export Component
export default ContentViewer;
