// Imports
import React from 'react';
import PropTypes from 'prop-types';
import { Heading } from '@radix-ui/themes'

// InputHeading component
const InputHeading = (props) => {

	// Return component
	return (

        <Heading 
            as='h5' 
            size='3'
        > {props.children}
        </Heading>

    )

};

// Props
InputHeading.propTypes = {
    children: PropTypes.string.isRequired
};

// Export Component
export default InputHeading;
